import React from 'react'
import moment from 'moment';
import axios from 'axios';

import { DatePicker ,Statistic,  Button} from 'antd';

import { onDateOk, disabledDate, validateFullDate, validateDateRange } from '../../../../services/services'
import '../../../../styles/dashboard/dashboard-main.scss';

const { RangePicker } = DatePicker
const { Countdown } = Statistic;

class SelectTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('security'),
      notBefore: null,
      notAfter: null,
      deadline: null,
      canUpdate: false,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nexProps) {
    this.setState({notAfter: nexProps.notAfter, notBefore: nexProps.notBefore})

    if(nexProps.room){
      const rangeOK = validateDateRange(nexProps.room.notBefore, nexProps.room.notAfter);
      if(!rangeOK.start){
        this.setState({deadline: moment(nexProps.room.notBefore)})
      }
    }

  }


  handleChange(e){
    const notBefore = e[0];
    const notAfter = e[1];

    const { room, handleOK } = this.props

    if(room){
      if(!moment(notBefore).isSame(moment(room.notBefore)) || !moment(notAfter).isSame(moment(room.notAfter))){
        if(validateFullDate(moment(notBefore),moment(notAfter), moment(room.createdAt))){
          this.setState({canUpdate: true})
          onDateOk(e, room, handleOK)
        }
        this.setState({canUpdate: true})
      }else{
        this.setState({canUpdate: false})
      }
    }else{
      this.setState({canUpdate: true})
    }
    
  }

  updateDate(){
    const { notAfter, notBefore} = this.state
    const { room } = this.props

    const data = {
			_id: room._id,
			notAfter: moment(notAfter, 'defaultFormatUtc'),
			notBefore:  moment(notBefore, 'defaultFormatUtc')
    }

		if(!validateFullDate(moment(notBefore),moment(notAfter), moment(room.createdAt))){
			return false
		}

			axios.put("/api/v1/room/", data, {
				headers: {
					'Authorization': `Bearer ${this.state.token}`,
				}
			}).then((response) => {
				const room = response.data.content;

				if(room.clients){
					const clients = room.clients.sort((a, b) => a.state.localeCompare(b.state) * -1);
					room.clients = clients.map((rw, i) => Object.assign(rw, { number_log: i + 1 }));
        }

        if(response.data.status !== 400){
          this.props.handleUpdate(room);
          this.setState({canUpdate: false})
        }


			}).catch((error) => {
				console.log(error);
			});
  }

  render() {
    const {handleOK, room, hasUpdate} = this.props;
    const { notAfter, notBefore, deadline, canUpdate} = this.state;
    const format = "YYYY-MM-DD || HH:mm"
		const correctStart = moment(notBefore) <= moment().add(1, "minute")
		const correctEnd = moment(notAfter) >= moment();

    console.log("room",room);

    return (
      <>
          <RangePicker
            showTime
            ranges={{
              'Now': [moment().add(2,'minutes'), moment().add(1,'days').add(3,'minutes')],
              'In 5 minutes': [moment().add(5,'minutes'), moment().add(1,'days').add(6,'minutes')],
              'In 1 hour': [moment().add(1,'hours'), moment().add(25,'hours')],

            }}
            format={format}
           // onChange={(event) => onDateOk(event, room, handleOK)}
            onChange={(event) => this.handleChange(event)}
            disabledDate={(event) => disabledDate(event, room) }
            {...( (notAfter && notBefore && notAfter && notBefore !== null && notAfter !== null) && {defaultValue:[ moment(notBefore, format),  moment(notAfter, format)]})}
            {...( (notAfter && notBefore && notAfter && notBefore !== null && notAfter !== null) && {value:[ moment(notBefore, format),  moment(notAfter, format)]})}

            />

            {hasUpdate &&
            <>
                {canUpdate &&
                <Button type="primary" onClick={() => this.updateDate()} data-before={notBefore} data-after={notAfter}>Update Date</Button>
                }

                {!correctStart &&
                    <span className="p-1 error ml-2 bright-pink">Event Not Started </span>
                }

              	{!correctEnd && <span className="p-1 error ml-2 bright-pink">Event Ended </span>}

                {!correctStart &&
                    <Countdown className="p-1 error ml-2 bright-pink" value={deadline} onFinish={(e) => {window.location.reload()}} />
                }

                </>

            }
      </>
     )
  }
}

export { SelectTime }
