import React from 'react';
import { Form, Input, Button } from 'antd';
import { Container } from 'react-bootstrap';
import { UserOutlined } from '@ant-design/icons';
import axios from 'axios';

import './style.scss';


class ForgotForm extends React.Component {
    state = {
        showForm: true,
        showMessage: false
    };
    formRef = React.createRef();

    handleSubmit = values => {
        const { username } = values;
        if(username) {
            axios.post('/api/v1/auth/recover', { username })
            .then((response) => {
                console.log(response);
                this.setState({ showForm: false, showMessage: true });
            }).catch((error) => {
                // handle error
                console.log(error);
            });
        } else {
            this.formRef.validateFields((err, values) => {
                if(!err) {
                    console.log('Received values of form: ', values);
                }
            });
        }

    };


    render() {
        const { showForm, showMessage } = this.state;

        return (
            <Container className="login">
                { showForm &&
                <Form ref={ this.formRef } onFinish={ this.handleSubmit } className="login-form">
                    <Form.Item>
                        <h4>Recover password</h4>
                    </Form.Item>
                    <Form.Item name="username" label="Username"
                               rules={ [{ required: true, message: 'Please input your Username!' }] }>
                        <Input
                            prefix={ <UserOutlined style={ { color: 'rgba(0,0,0,.25)' } }/> }
                            placeholder="jdoe_9_12_18"
                        />
                    </Form.Item>
                    <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Recover
                        </Button>
                        <Button type="primary" className="login-form-button"
                                onClick={ () => this.props.history.push('/dashboard') }>
                            Cancel
                        </Button>
                    </div>
                </Form>
                }
                { showMessage &&
                <p className="message">Done!<br/>An email was sent to your mailbox.</p>
                }
            </Container>
        );
    }
}

export { ForgotForm };
