import React from 'react';
import { Form, Input, Button } from 'antd';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import './style.scss';


class LoginForm extends React.Component {
    state = {
        error: ''
    };
    formRef = React.createRef();

    handleSubmit = values => {
        console.log(values);
        const { username, password } = values;
        if(username && password) {
            axios.post('/api/v1/auth/login', { username, password })
            .then(async (response) => {
                if(response.data.content.token) {
                    await localStorage.setItem('security', response.data.content.token);
                    this.props.history.push('/dashboard');
                } else if(response.data) {
                    this.setState({ error: response.data.message });
                } else {
                    await localStorage.setItem('security', '');
                    this.props.form.setFields({
                        username: {
                            value: values.username,
                            errors: [new Error('')],
                        },
                        password: {
                            value: values.password,
                            errors: [new Error('User or Password Incorrect')],
                        }
                    });
                }
            }).catch((error) => {
                // handle error
                console.log(error);
            });
        } else {
            this.setState({
                errorUser: !username ? true : false,
                errorPass: !password ? true : false
            });
        }
    };

    remember = value => {
        console.log(value.target.checked);
    };


    render() {
        return (
            <Container className="login full-viewport background-mesh">
                <Form ref={ this.formRef } onFinish={ this.handleSubmit } className="login-form">
                    <Form.Item>
                        <h4>Log in</h4>
                    </Form.Item>
                    <Form.Item name="username" rules={ [{ required: true, message: 'Please input your username!' }] }>
                        <Input
                            prefix={ <UserOutlined style={ { color: 'rgba(0,0,0,.25)' } }/> }
                            placeholder="Username"
                        />
                    </Form.Item>
                    <Form.Item name="password" rules={ [{ required: true, message: 'Please input your Password!' }] }>
                        <Input
                            prefix={ <LockOutlined style={ { color: 'rgba(0,0,0,.25)' } }/> }
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item style={ { textAlign: 'center' } }>
                        {/*<Checkbox id="rememberMe" onChange={this.remember}>Remember me</Checkbox>*/ }
                        { this.state.error !== '' && <span className="error" style={ {
                            position: 'absolute',
                            top: '-22px',
                            textAlign: 'center',
                            width: '134px'
                        } }>{ this.state.error }</span> }
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                    </Form.Item>
                    <Form.Item style={ { textAlign: 'center' } }>
                        <a className="login-form-forgot" href="/forgot">
                            Forgot password
                        </a>
                    </Form.Item>
                    <Form.Item style={ { textAlign: 'center' } }>
                        <a href="/register">Register Now!</a>
                    </Form.Item>
                </Form>
            </Container>
        );
    }
}

export { LoginForm };
