import React from 'react'
import '../../../styles/dashboard/dashboard-main.scss'

const TopContent = (props) => {
  return (
    <div className="top-content">
      {props.children}
    </div>
  )
}

export { TopContent }
