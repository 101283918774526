import React from 'react'
import axios from 'axios';

import {Table} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import '../../../../styles/dashboard/dashboard-main.scss';
// import {CreateEvent} from "./components/CreateEvent"

class ListOffices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: null,
      token: localStorage.getItem('security'),
      showTable: true,
      searchText: '',
      searchedColumn: '',
      columns: [
        {
          title: 'Office',
          dataIndex: 'name',
          key: 'name',
          render: (name) => (
            <span id={name}>{name}</span>
          ),
        },
        {
          title: 'Action',
          dataIndex: '',
          key: 'x',
          render: (office) => (
            <DeleteOutlined onClick={() => this.updateOffices(office.name)} />
          )
        },
      ]
    }

    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    //this.getRooms()
  }

  updateOffices(id) {
    const {room} = this.props;

    const data = {
        _id: room._id
    }
    let offices = [];

    if (id) {
        //si viene un id, es delete, borro las oficinas
        room.offices.map((k, i) => {
            if (id !== k.name)
                offices.push(k)
            return i;
        })
    }

    data.offices = offices

    axios.put("/api/v1/room/", data, {
        headers: {
            'Authorization': `Bearer ${this.state.token}`,
        }
    }).then((response) => {
        this.props.onUpdate(response.data.content);
    }).catch((error) => {
        console.log(error);
    });
  }


  deleteRoom(roomId) {
    const _this = this;
    axios.delete('/api/v1/room/' + roomId, {
      headers: {
        'Authorization': "Bearer " + _this.state.token
      }
    }).then((response) => {
      _this.getRooms()
    })
      .catch((error) => {
        _this.setState({ showTable: true })
        console.log(error);
      });
  }

  handleCancel() {
    this.setState({ showTable: true });
  }

  render() {
    const {data} = this.props

    return (
        <Table
        className="offices-table"
        columns={this.state.columns}
        dataSource={data}
        size="small"
        pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['10', '25']}}
        rowKey={offices => offices._id}
        />

    )
  }
}

export { ListOffices }
