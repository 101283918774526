const emailValidationErrorMessage =  "Oops! Only emails from approved domains are allowed. Please use your company email address."
const passwordValidationErrorMessage =  "Passwords don't match."
const successMessage = "Your changes have been made successfully!";
const serverError =  "Oops! Something went wrong"
const fullNameError = "Please input your fullname!"
const userNameError = "Please input your username!"
const emailError = "Please input your E-mail!"
const passwordError = "Please input your password!"
const confirmPaswordError = "Please confirm your password!"


export {
    emailValidationErrorMessage,
    passwordValidationErrorMessage,
    successMessage,
    serverError,
    fullNameError, 
    emailError, 
    userNameError, 
    passwordError, 
    confirmPaswordError
}