import React from 'react';
import { Layout } from 'antd';
import '../../../styles/dashboard/dashboard-main.scss'

const { Footer } = Layout;

class FireFooter extends React.Component {
  render() {

    return (
      <Footer style={{ textAlign: 'center' }}>Dashboard Created by VML</Footer>
    )
  }
}

export { FireFooter }
