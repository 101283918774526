import React from 'react';
import {Table, message, Divider, Popconfirm } from 'antd';
import axios from 'axios';

import { LikeOutlined, DislikeOutlined, KeyOutlined, EditOutlined } from '@ant-design/icons';
import { UserRol, UserStatus } from '../../common/Utils';

import '../../../../styles/dashboard/dashboard-main.scss';

class ListBusinesses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('security'),
      columns: [
        {
          title: '#',
          dataIndex: 'number_log',
        },
        {
          title: 'Office',
          dataIndex: 'name',
          sorter:	(a, b) => a.name.localeCompare(b.name),
        },
        {
          title: 'Country',
          dataIndex: 'country',
          sorter:	(a, b) => a.country.localeCompare(b.country),
        },
        {
          title: 'Region',
          dataIndex: 'region',
          filters: [
            {
                text: 'APAC',
                value: 'APAC',
            },
            {
                text: 'Europe',
                value: 'Europe',
            },
            {
                text: 'LATAM',
                value: 'LATAM',
            },
            {
                text: 'EMEA',
                value: 'EMEA',
            },
            {
                text: 'NA',
                value: 'NA',
            },
            {
                text: 'SA',
                value: 'SA',
            },
          ],
          onFilter: (value, user) => user.region.indexOf(value) === 0,
          sorter:	(a, b) => a.region.localeCompare(b.region),
        }/*,
        {
          title: 'Action',
          dataIndex: '',
          key: 'x',
          render: () => (
            <span>
              <Icon type="edit" />
              <Divider type="vertical" />
              <Icon type="delete" />
            </span>
          )
        },*/
      ]
    }
  }

  render() {
    const { columns } = this.state
    const { data } = this.props

    return (
        <Table
          columns={columns}
          dataSource={data}
          rowKey={office => office._id}
        />

    )
  }
}

export { ListBusinesses }
