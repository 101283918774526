import React from 'react';
import {message } from 'antd';
import axios from 'axios';
import {emailValidationErrorMessage, successMessage, serverError} from './config';
import { UserForm } from './userForm';

import '../../../../styles/dashboard/dashboard-main.scss';

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('security'),
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = values => {
    const { fullname, email, password, old_password } = values;
    const {type, createCallback, user} = this.props;
    const isProfile = type === "profile" ? true: false;

    const data = {
      _id: user._id,
      email: email || user.email,
      fullname: fullname || user.fullname
    }

    console.log(data);
    const url = isProfile ? '/api/v1/user/profile' : "/api/v1/user/"

    axios.put(url, data,{
      headers: {
        'Authorization': `Bearer ${this.state.token}`,
      }
    })
    .then((response) => {
      if (response.data.content === "Invalid domain") {
        message.error(emailValidationErrorMessage)
      } else {
        if(response.data.status !== 400 && response.data.status !== 500) {
          if (!old_password) {
            message.success(successMessage)
            if(createCallback) createCallback();
          }
        }
      }
    }).catch((error) => {
      console.log(error);
    })

    if(isProfile) {
     if ( old_password && password ) {
        const data_pass = {
          "_id": user._id,
          "currentPassword": old_password,
          "newPassword": password
        }
        axios.put('/api/v1/user/password', data_pass,{
          headers: {
            'Authorization': `Bearer ${this.state.token}`,
          }
        })
          .then((response) => {
            if(response.data.status !== 400 && response.data.status !== 500) {
              message.success(successMessage)
              if(createCallback) createCallback();

            }else{
              message.error(response.data.content.message);
            }
          }).catch((error) => {
            // handle error
            message.error(serverError)
            console.log(error);
          })
        }
      }
  };

  render() {
    const {cancelCallback, user, type} = this.props

    return (
        <UserForm type={type}
          onFinish={this.handleSubmit}
          user={user}
          onCancel={cancelCallback}
        ></UserForm>
    )
  }
}

export { EditUser }
