/* eslint-disable eqeqeq */
import React from 'react';

import axios from 'axios';
import moment from 'moment';
import openSocket from 'socket.io-client';

import { Layout,Button, Collapse, Popconfirm, message, Card } from 'antd';

import { Image } from 'react-bootstrap';
import { SelectOffice } from './components/SelectOffice'
import { SelectTime } from './components/SelectTime'
import { StatusRoom } from '../common/Utils';

import {timeValidationError, validateDateRange} from '../../../services/services'

import { SliderMenu, MenuProfile } from '../common';
import logo from '../../../images/vml-logo-white.png';

import '../../../styles/dashboard/dashboard-main.scss';
import '../../../styles/dashboard/components/event-component.scss';

import { ListOffices } from './components/ListOffices';
import { Results } from './components/Results';
import { ListClients } from './components/ListClients';
import { ListIdeas } from './components/ListIdeas';

const { Header, Content } = Layout
const { Panel } = Collapse

class Event extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		dateInRange : {start: false, end: false},
		validDate: true,
		roomId: this.props.match.params.event || null,
		token: localStorage.getItem('security'),
		profile: null,
		socket: null,
		selectedRowKeys: [], // Check here to configure the default column
		room: {},
		voters: {
			total: 0,
			entering: 0,
			online: 0,
			offline: 0,
			disabled: 0
		},
		notAfter: null,
		notBefore: null,
		selectedOffices: undefined,
	}
		this.handleTreeChange = this.handleTreeChange.bind(this);
		this.handleDateOK = this.handleDateOK.bind(this);
		this.updateOffices = this.updateOffices.bind(this);
		this.updateRoom = this.updateRoom.bind(this);
	}


	componentDidMount() {
		localStorage.debug = 'socket.io-client:socket';
		this.getRoom();
		let socket

		if (process.env.NODE_ENV==="production"|| process.env.NODE_ENV==="stage" || process.env.NODE_ENV==="docker") {
			socket=openSocket({ path: "/socket", transports: [ 'websocket' ] });
		} else {
			socket=openSocket("http://localhost:3000/",{ path: "/socket", transports: [ 'websocket' ] });
		}

		socket.on('moderator', data => {
			if (data.state == 'joined') {
				console.log('Conectado a ' + data.room);
			}
			if (data.state == 'room changed') {
				this.getRoom();
			}
		})

		socket.on('error', error => {
			console.log(error)
		});

		socket.on('reconnect_attempt', () => {
			console.log("reconnect_attempt");
			//socket.io.opts.transports = ['polling', 'websocket'];
		  });

		socket.emit('enter', {
			access: this.state.token,
			room: this.state.roomId
		});

		this.setState({ socket });
	}

	generateCode() {
		if (this.state.room.state != 'open') return null
		const data = {
			_id: this.state.roomId
		}
		axios.put("/api/v1/room/code", data, {
			headers: {
				'Authorization': `Bearer ${this.state.token}`,
			}
		})
			.then((response) => {
				this.setState({ room: response.data.content })
			})
			.catch((error) => {
				// handle error
				console.log(error);
			});
	}


	openEvent(data){
		axios.put("/api/v1/room/open" , data, {
			headers: {
				'Authorization': `Bearer ${this.state.token}`,
			}
		}).then((response) => {
			this.setState({ room: response.data.content });
		}).catch((error) => {
			console.log(error);
		})
	}

	closeEvent() {
		const { socket } = this.state;

		this.state.room.ideas.map((idea)=>{
			if (idea.state == 'votation') {
				this.closeVotation(idea)
			}
		})

		socket.emit('close_room', {
			access: this.state.token,
			room: this.state.roomId
		})
	}

	toggleRoomState() {
		const { room } = this.state;
		const data = {
			_id: this.state.roomId
		}

		if (room.state == 'open' || room.state == 'score') {
			this.closeEvent()
		} else {
			this.openEvent(data);
		}
	}

	getRoom() {
		axios.get("/api/v1/room/" + this.state.roomId, {
			headers: {
				'Authorization': `Bearer ${this.state.token}`,
			}
		}).then((response) => {
			const room = response.data.content;
			const clients = room.clients.sort((a, b) => a.state.localeCompare(b.state) * -1);
			room.clients = clients.map((rw, i) => Object.assign(rw, { number_log: i + 1 }));

			const voters = {
				total: clients.length,
				online: clients.filter(a=>a.state=='enabled').length
					+clients.filter(a=>a.state=='voting').length
					+clients.filter(a=>a.state=='voted').length
					+clients.filter(a=>a.state=='waiting').length
				,
				offline: clients.filter(a=>a.state=='disconnected').length,
				enabled: clients.filter(a=>a.state=='enabled').length,
				voting: clients.filter(a=>a.state=='voting').length,
				voted: clients.filter(a=>a.state=='voted').length,
				waiting: clients.filter(a=>a.state=='waiting').length,
				entering: clients.filter(a=>a.state=='entering').length,
				disabled: clients.filter(a=>a.state=='disabled').length,
			};

			this.setState({ room, notAfter: moment(room.notAfter)._d, notBefore: moment(room.notBefore)._d, voters });

			const rangeOK = validateDateRange(moment(room.notBefore)._d, moment(room.notAfter)._d);
			this.setState({ dateInRange: rangeOK });

		}).catch((error) => {
			console.log(error);
			this.props.history.push("/dashboard");
		});
	}

	///CALLBACK FOR DATE

	handleDateOK(notBefore, notAfter, valid) {
		if(valid) {
		   this.setState({validDate: true})
		   if(notBefore !== this.state.notBefore) this.setState({ notBefore: moment(notBefore, 'defaultFormatUtc') })
		   if(notAfter !== this.state.notAfter) this.setState({ notAfter: moment(notAfter, 'defaultFormatUtc')  })
		  } else {
			message.error(timeValidationError);
			this.setState({validDate: false})
		}
	}

	//CALLBACK TO UPDATE ROOM

	updateRoom(room) {
		//this.setState({ room });
		this.getRoom();
	}


	///CALLBACK FOR OFFICES!

	updateOffices(data) {
		this.setState({selectedOffices : undefined})
		this.setState({ room: data});
	}

	handleTreeChange = value => {
		this.setState({ selectedOffices: value })
	}

	render() {
		const { room, socket, dateInRange } = this.state
		//Forzado de votes y score 0
		if(Object.entries(room).length > 0 ){
			room.ideas.map((i, index) =>{
				if(!i.votes || !i.score){
					i.score = "0";
					i.votes = 0;
					room.ideas[index] = i
				}
			})
		}

		console.log("start", dateInRange.start );
		console.log("start", dateInRange.end );


		return (
			<Layout>
				<Header className="header">
					<div className="logo">
						<Image style={{width: '100px'}} src={logo} />
					</div>
					<MenuProfile />
				</Header>

				<Content className="event-component">
					<Layout className="slider-menu" style={{ background: '#ccc' }}>
						<SliderMenu selectedMenu="1" />
						<Content style={{ padding: '10px 10px', minHeight: 285,  background: '#ccc'  }}>
							<Card title={`${room.eventName}`}>

							<span style={{position:"absolute", top: '20px', right: '20px'}}>{StatusRoom(room.state)}</span>

							{/* {code just when out just show close in range} */}
							{((!dateInRange.start || !dateInRange.end) && (room.state == 'open' || room.state == 'score' || room.state == 'votation')) &&

							<Content className="code-container mb-3">
								<div>
									<Popconfirm placement="topLeft" title='Are you sure?' onConfirm={() => this.closeEvent()} okText="Yes" cancelText="No">
										<Button type="primary" data-roomstatus={room.state}>
											{
												room.state != 'open' && room.state != 'score' && room.state != 'votation' ? 'Start event' : 'Close event'
											}
										</Button>
									</Popconfirm>
								</div>
							</Content>
							}



							{/* {code just when in range} */}
							{dateInRange.start && dateInRange.end &&
							<Content className="code-container">
								<div>
									<Popconfirm placement="topLeft" title='Are you sure?' onConfirm={() => this.toggleRoomState()} okText="Yes" cancelText="No">
										<Button type="primary" data-roomstatus={room.state}>
											{
												room.state != 'open' && room.state != 'score' && room.state != 'votation' ? 'Start event' : 'Close event'
											}
										</Button>
									</Popconfirm>

									<Button type="primary" onClick={() => this.generateCode()}>Generate Code</Button>
								</div>
								<span className="access-code">{(room.state != 'closed' && room.accessCode) || <b>Start event to generate code</b>}</span>
							 </Content>
							}

							<Content className={`dateContainer pb-2 align-items-center ${!this.state.dateInRange ? "date-error" : ""}`}
								data-after={room.notAfter} data-before={room.notBefore}>
								{(room != null && room.notAfter !== null && room.notBefore !== null ) &&
									<SelectTime type="create"
										handleOK={this.handleDateOK}
										origNotAfter={room.notAfter}
										notAfter={this.state.notAfter}
										notBefore={this.state.notBefore}
										room = {room}
										hasUpdate={true}
										handleUpdate={this.updateRoom}
									>
									</SelectTime>
								}

							</Content>
							{ (room != null && room.ideas !=null && room.ideas.length > 0) &&

								<Results data={room.ideas} room={room} socket={socket}></Results>

							}
							<Collapse className="event-collapse" defaultActiveKey={['1']}>
								{room != null && room.eventName != null &&
									<Panel header="Event" key="1">
										<Collapse className="offices-collapse" defaultActiveKey={['3']}>
											<Panel header="Offices in event" key="3" >
												<ListOffices
													data={room.offices}
													onUpdate={this.updateOffices}
													room={room}
												/>

												<br></br>

												<SelectOffice type=""
													selectedOffices={this.state.selectedOffices}
													handleChange={this.handleTreeChange}
													handleAdd={this.updateOffices}
													className="ideas_table"
													hasPlus={true}
													room={room}
												></SelectOffice>

											</Panel>
										</Collapse>

										<Collapse className="ideas-collapse" defaultActiveKey={['3']}>
											<Panel header="Ideas for event" key="3" >
											 <ListIdeas
												room={room}
												socket={socket}
												ideas={room.ideas}
												handleUpdate={this.updateRoom}
												></ListIdeas>

											</Panel>
										</Collapse>


									</Panel>
								}
							</Collapse>

							<Collapse className="event-collapse clients-section" defaultActiveKey={['2']}>
								<Panel header="Voters" key="2">
									<ListClients room={room} voters={this.state.voters} socket={socket}></ListClients>
								</Panel>
							</Collapse>
							</Card>
						</Content>
					</Layout>
				</Content>
			</Layout>
		)
	}
}
export { Event }