import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import { Button } from 'antd';

import './style.scss';

import fireIcon from '../../../images/fire-icon.png';


class Thanks extends React.Component {
  state = {
    socket: this.props.socket
  }

  render() {
    document.body.classList.remove('scroll');

    return (
      <Container className="thanks full-viewport background-mesh">
        <div>
          <Row className="justify-content-sm-center">
            <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="text-center">
              <Image style={ { width: '70px' } } src={ fireIcon } round="true" fluid/>
              <h5 className="text-center p-4 white">Thanks for voting!</h5>
              <p>Please wait <br /> for next Award</p>
            </Col>
          </Row>
          <Row className="justify-content-sm-center pb-3">
            <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="text-center">
              <Button type="link" size="large" className="btn-leave" onClick={() => this.props.onResetSession(this.state.socket)}>Leave</Button>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export { Thanks };
