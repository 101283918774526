import React from 'react';
import {
    Container,
    Row,
    Col,
    Image
} from 'react-bootstrap';

import { Button } from 'antd';

import './style.scss';

import waiting from '../../../images/waiting.gif';
import fire from '../../../images/fire.png';
import fireIcon from '../../../images/fire-icon.png';


class Waiting extends React.Component {
  state = {
      socket: this.props.socket
  };

  room_closed = () => {
    return (
      <div style={{ flexGrow: 0.6 }}>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="text-center p-3">
            <Image style={ { width: '80px' } } src={ fire } round="true" fluid/>
            <h6 className="text-center white pt-4">Thank you <br /> for participating</h6>
          </Col>
        </Row>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="pl-5 pr-5 text-center">
            <Button size="large" className="btn-orange mb-4" onClick={() => this.props.onResetSession(this.state.socket)}>Leave</Button>
          </Col>
        </Row>
      </div>
    );
  };

  loader = () => {
    return (
      <div>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="text-center pt-5 pb-5">
            <div className="loader"></div>
          </Col>
        </Row>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="text-center p-5">
            <h6 className="text-center cyan p-5">Please wait host confirmation.</h6>
          </Col>
        </Row>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="pt-3 pl-5 pr-5 text-center">
            <Button size="large" className="btn-orange" onClick={() => this.props.onResetSession(this.state.socket)}>Restart</Button>
          </Col>
        </Row>
      </div>
    );
  };

  admin_approval = () => {
    return (
      <div style={{ flexGrow: 0.6 }}>
        <Row className="justify-content-sm-center pt-5">
          <Col xs sm={ 10 } md={ 6 } className="text-center mt-5">
            <Image style={ { width: '50px' } } src={ fireIcon } round="true" fluid/>
            <h6 className="text-center white mt-3">Please wait <br />The event will start shortly</h6>
          </Col>
        </Row>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } className="text-center">
            <Button type="link" size="large" className="mt-5 btn-leave" onClick={() => this.props.onResetSession(this.state.socket)}>Leave</Button>
          </Col>
        </Row>
      </div>
    );
  };

  beforeVote = () => {
    return (
      <div style={{ flexGrow: 1 }}>
        <Row className="justify-content-sm-center pt-4">
          <Col xs sm={ 10 } md={ 6 } className="text-center mt-5">
            <Image style={ { width: '50px' } } src={ fireIcon } round="true" fluid/>
            <p className="text-center p-4 white">
              Award a score of <br />0-3 for each of the following <br />4 criteria:
            </p>
          </Col>
        </Row>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } className="text-center">
            <h4 className="focus-color">focus</h4>
          </Col>
        </Row>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } className="text-center">
            <h4 className="inspiration-color">inspiration</h4>
          </Col>
        </Row>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } className="text-center">
            <h4 className="relevance-color">relevance</h4>
          </Col>
        </Row>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } className="text-center">
            <h4 className="effect-color">effect</h4>
          </Col>
        </Row>
        <Row className="justify-content-sm-center mt-5">
          <Col xs sm={ 10 } md={ 6 } className="pl-5 pr-5 text-center">
            <Button size="large" className="btn-leave" type="link" onClick={() => this.props.onResetSession(this.state.socket)}>Leave</Button>
          </Col>
        </Row>
      </div>
    );
  };

  afterVote = () => {
    return (
      <div style={{ flexGrow: 0.5 }}>
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="text-center">
            <Image style={ { width: '70px' } } src={ fireIcon } round="true" fluid/>
            <h5 className="text-center p-4 white">Thanks for voting!</h5>
            <p>Please wait <br /> for next Award</p>
          </Col>
        </Row>
        <Row className="justify-content-sm-center pb-3">
          <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="text-center">
            <Button type="link" size="large" className="btn-leave" onClick={() => this.props.onResetSession(this.state.socket)}>Leave</Button>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { entry } = this.props;
    document.body.classList.remove('scroll');

    return (
      <Container className="waiting full-viewport background-mesh">
        {/*
          * 1 enter rooms
          * 2 waiting before voting (focus, inspire, relevance, effect)
          * 3 thanks for voting
          */ }

        { (entry === 1) && this.admin_approval() }
        { (entry === 2) && this.beforeVote() }
        { (entry === 3) && this.afterVote() }
        { (entry === 4) && this.loader() }
        { (entry === 5) && this.room_closed() }
      </Container>
    );
  }
}

export { Waiting };
