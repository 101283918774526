import React from 'react';
import { Avatar, Popconfirm, Button, Drawer } from 'antd';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { UserOutlined } from '@ant-design/icons';

import '../../../styles/dashboard/dashboard-main.scss';
import '../../../styles/dashboard/components/menu-profile.scss';

import { EditUser } from '../Users/components/EditUser';

class MenuProfile extends React.Component {

  state = {
    token: localStorage.getItem('security'),
    loading: true,
    profile: {
      fullname: '',
      username: '',
      email: '',
      rol: '',
    },
    visible: false
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.getProfile();
  }

  getProfile() {
    axios.get('/api/v1/auth/profile', {
      headers: {
        'Authorization': `Bearer ${this.state.token}`,
      }
    }).then((response) => {
      console.log(response)
      this.setState({ profile: response.data.content.user, loading: false})
    }).catch((error) => {
      // handle error
      console.log(error);
    })
  }

  render() {
    if (!this.state.loading) {
      return <div className="profile">
        {this.state.profile.fullname}
        <Avatar style={{ backgroundColor: '#2c97cf' }} icon={<UserOutlined />} className="ml-2" onClick={this.showDrawer}/>
        <Drawer
            title="Profile"
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
            getContainer={false}
            style={{ position: 'absolute' }}
          >
              <Container className="register">
                <EditUser type="profile" user={this.state.profile}
                  createCallback={e=> {window.location.reload()}}
                ></EditUser>
              </Container>

            <Popconfirm className="logoff" placement="topLeft" title='Log Out?' onConfirm={function() {localStorage.removeItem('security'); window.location.reload()}} okText="Yes" cancelText="No">
              <Button>Log out</Button>
            </Popconfirm>
          </Drawer>
      </div>
    } else {
     return <div></div>
    }
  }
}

export { MenuProfile }
