import React from 'react'
import axios from 'axios';

import {  TreeSelect, Select, message, Row, Col } from 'antd';
import '../../../../styles/dashboard/dashboard-main.scss';
import { PlusCircleOutlined } from '@ant-design/icons';

const { TreeNode } = TreeSelect
const { Option } = Select

class SelectOffice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('security'),
      showTable: true,
      eventName: "",
      officesByCountry: null,
      selectedOffices: {},
      hostCountrySelected: "",
      notBefore: "",
      notAfter: "",
      errorEventName: false,
      errorHostCountry: false,
      errorDate: false,
      addOfficeDisabled: false
    }
  }

  componentDidMount() {
    // Listado de Oficinas por paises
    axios.get('/api/v1/office/parent/country')
      .then((response) => {
        this.setState({ officesByCountry: response.data.content })
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }

  handleTreeChange = value => {
    // habilito el icono para enviar a guardar los paises luego de seleccionarlos
    const {hasPlus} = this.props
    if(hasPlus){
        if (value.length > 0) {
            this.setState({ addOfficeDisabled: true })
        } else {
            this.setState({ addOfficeDisabled: false })
        }
    }

    const valueSelect = value.length && typeof value === "string" ? value[value.length-1].split("|")[0]:'novalue'
    // evaluo que no este duplicado
    if (document.getElementById(valueSelect)) {
        message.error('Duplicated');
    } else {
        this.setState({ selectedOffices: value })
        this.props.handleChange(value);
    }

 }

 updateOffices(id) {
    const { selectedOffices, room} = this.props;

    const data = {
        _id: room._id
    }
    let offices = room.offices || [];

    if (id) {
        //si viene un id, es delete, borro las oficinas
        offices = []
        room.offices.map((k, i) => {
            if (id !== k.name)
                offices.push(k)
            return i;
        })
    } else {
        // sino las agrego
        selectedOffices.map((k, i) => {
            if (k != null) {
                offices.push({
                    name: k.split('|')[0],
                    country: k.split('|')[1],
                    id: k.split('|')[2]
                });
                return i;
            }
        })
    }

    data.offices = offices
    // limpio las oficinas seleccionadas en el add
    this.setState({selectedOffices : {}})

    axios.put("/api/v1/room/", data, {
        headers: {
            'Authorization': `Bearer ${this.state.token}`,
        }
    }).then((response) => {
        this.props.handleAdd(response.data.content);
    }).catch((error) => {
        console.log(error);
    });
}




  render() {
    const {officesByCountry} = this.state
    const { type, error, selectedOffices, handleFocus, className} = this.props;

    return (
        type === "select" ?
            <>
            <Select placeholder="Select Host Country" style={{ width: '100%' }} className={error ? 'error' : 'nnnnn'}
            onFocus={() => handleFocus()}
            onChange={(e) => this.handleTreeChange(e)}>
                {officesByCountry &&
                Object.keys(officesByCountry).map((country, i) => {
                    return (
                        <Option value={country} key={country}>{country} </Option>
                    )
             })}
            </Select>
            {error ? <span style={{ color: 'white', display: 'inline-flex', alignItems: 'center', paddingLeft: '20px', height: '25px' }}>* Host Country is required </span> : ''}
           </>
                :
            <>


           <Row gutter={[16, 16]} className="m-2">
            <Col span={20} >
                <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    value={selectedOffices}
                    dropdownStyle={{ maxHeight: className === "ideas_table" ? 250 : 400, overflow: 'auto' }}
                    placeholder={`${className === "ideas_table" ? "Add" : "Select"} Office`}
                    allowClear
                    multiple
                    onChange={e => this.handleTreeChange(e)}
                >
                {officesByCountry &&
                    Object.keys(officesByCountry).map((country, i) => {
                    return (
                        <TreeNode value={country} title={country} selectable={false}>
                        {officesByCountry[country].map((officeName, j) => {
                            return (
                            className === "ideas_table" ?
                            <TreeNode className={officeName.name.replace(/ /g, '_')} value={officeName.name + '|' + officeName.country + '|' + officeName.key} title={officeName.name} key={officeName.key}></TreeNode>
                                :
                            <TreeNode value={officeName} title={officeName.name} key={officeName.key}></TreeNode>
                            )
                        })}
                        </TreeNode>
                    )
                    })
                }
                </TreeSelect>

            </Col>
                <Col span={2} >
                    {this.state.addOfficeDisabled && <PlusCircleOutlined className="addOffice" onClick={() => this.updateOffices()} />}
                </Col>
            </Row>

            <Col xs={12} sm={12} md={12} lg={12}>
            </Col>
            </>
     )
  }
}

export { SelectOffice }
