import React from 'react'
import axios from 'axios';

import {Table} from 'antd';

import { StatusRoom } from './../../common/Utils';
import { EditOutlined, DeleteOutlined , SearchOutlined} from '@ant-design/icons';
import { Divider, Button, Input, Space } from 'antd';

import '../../../../styles/dashboard/dashboard-main.scss';
// import {CreateEvent} from "./components/CreateEvent"

class ListEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: null,
      token: localStorage.getItem('security'),
      showTable: true,
      searchText: '',
      searchedColumn: '',
      columns: [
        {
          title: '#',
          dataIndex: 'number_log',
        },
        {
          title: 'Name',
          dataIndex: 'eventName',
          ...this.getColumnSearchProps('eventName','Name')
        },
        {
          title: 'Office',
          dataIndex: 'place',
          ...this.getColumnSearchProps('place','Office')
        },
        {
          title: 'Creator',
          dataIndex: 'moderator',
          ...this.getColumnSearchProps('moderator','Creator'),
          render: (event) => (
            <div sytle={{ textAlign: 'center'}}>
              {(event !=null && event.length > 0 ) ? event[0].username: ''}
            </div>
          )
        },
        {
          title: 'Status' ,
          dataIndex: 'state',
          filters: [
            {
                text: 'Created',
                value: 'created',
            },
            {
                text: 'Open',
                value: 'open',
            },
            {
              text: 'Votation',
              value: 'votation',
            },
            {
              text: 'Score',
              value: 'score',
            },
            {
              text: 'Closed',
              value: 'closed',
            },
          ],
          onFilter: (value, event) => event.state.indexOf(value) === 0,
          sorter:	(a, b) => a.state.localeCompare(b.state),
          render: StatusRoom
        },
        {
          title: 'Action',
          dataIndex: '',
          render: (event) => (
            <span>
              <EditOutlined type="edit" onClick={() => this.editRoom( event._id)} />
              {(event != null && event.state == 'created') &&
                <Divider type="vertical" />
              }
              {(event != null && event.state == 'created') &&
                <DeleteOutlined type="delete" onClick={() => this.deleteRoom(event._id)} />
              }
            </span>
          )
        },
      ]
    }

    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    this.getRooms()
  }

  getRooms() {
    const {history} = this.props.props
    // Listado de Rooms
    axios.get('/api/v1/room/', {
      headers: {
        'Authorization': `Bearer ${this.state.token}`,
      }
    })
      .then((response) => {
        if (response.data.code === 'unauthorized') {
          history.push("/login")
        } else {
          this.setState({ events: response.data.content.map((rw, i) => Object.assign(rw, { number_log: i + 1 })) })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getColumnSearchProps = (dataIndex,dataLabel) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataLabel}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });
  //COLUMN LIST EVENTS

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  editRoom(roomId) {
    const {history} = this.props.props
    history.push({
      pathname: '/dashboard/events/' + roomId,
      roomId: roomId
    })
  }

  deleteRoom(roomId) {
    const _this = this;
    axios.delete('/api/v1/room/' + roomId, {
      headers: {
        'Authorization': "Bearer " + _this.state.token
      }
    }).then((response) => {
      _this.getRooms()
    })
      .catch((error) => {
        _this.setState({ showTable: true })
        console.log(error);
      });
  }

  handleCancel() {
    this.setState({ showTable: true });
  }

  render() {
    const {events} = this.state

    return (
        <Table
          className="event-table"
          columns={this.state.columns}
          dataSource={events}
          rowKey={event => event._id}
        />

    )
  }
}

export { ListEvents }
