import React from 'react'
import axios from 'axios';
import moment from 'moment';

import { Button, message } from 'antd';

import { Row, Col, InputGroup, FormControl } from 'react-bootstrap';

import { onDateOk, timeValidationError, disabledDate } from '../../../../services/services'

import '../../../../styles/dashboard/dashboard-main.scss';
import '../../../../styles/dashboard/components/create-event-component.scss';


import { SelectOffice } from './SelectOffice'
import { SelectTime } from './SelectTime'


class CreateEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('security'),
      showTable: true,
      eventName: "",
      selectedOffices: undefined,
      hostCountrySelected: "",
      notBefore: "",
      notAfter: "",
      errorEventName: false,
      errorHostCountry: false,
      errorDate: false,
    }

    this.handleDateOK = this.handleDateOK.bind(this);
    this.disabledDate = disabledDate.bind(this);
    this.onDateOk = onDateOk.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
  }

  // Create Room events

  handleTreeSelect = value => {
    console.log(value);
    this.setState({ selectedOffices: value })
  };

  handleSelect = value => {
    this.setState({ hostCountrySelected: value, errorHostCountry: false })
  }

  handleSelectFocus = value => {
    this.setState({ errorHostCountry: false })
  }

  handleDateOK(notBefore, notAfter, valid) {
    if(valid) {
       this.setState({ errorDate: false })

      //  if(notBefore !== this.state.notBefore) this.setState({ notBefore: notBefore })
      //  if(notAfter !== this.state.notAfter) this.setState({ notAfter: notAfter })

      if(notBefore !== this.state.notBefore) this.setState({ notBefore: moment(notBefore, 'defaultFormatUtc') })
      if(notAfter !== this.state.notAfter) this.setState({ notAfter: moment(notAfter, 'defaultFormatUtc')  })

      } else {
        message.error(timeValidationError);
        this.setState({ errorDate: true })
    }
   }

  createRoom() {
    let evtNameReq = false, hostCountryReq = false;
    const { eventName, hostCountrySelected, selectedOffices, notBefore, notAfter, errorDate } = this.state
    const { history } = this.props.props;

    if (errorDate) {
      message.error(timeValidationError);
      return null
    }

    if (eventName === '') {
      this.setState({ errorEventName: true })
      evtNameReq = true
    }

    if (hostCountrySelected === '') {
      this.setState({ errorHostCountry: true })
      hostCountryReq = true
    }

    if (evtNameReq || hostCountryReq) {
      return null
    }

    const data = {
      eventName: eventName,
      place: hostCountrySelected,
      ...(moment(notBefore).isValid() && { notBefore: moment(notBefore, 'defaultFormatUtc') }),
      ...(moment(notAfter).isValid() && { notAfter: moment(notAfter, 'defaultFormatUtc') }),
      ...(selectedOffices && { offices: selectedOffices })
    }

    axios.post('/api/v1/room/', data, {
      headers: {
        'Authorization': "Bearer " + this.state.token
      }
    })
      .then((response) => {
        history.push({
          pathname: '/dashboard/events/' + response.data.content._id,
          roomId: response.data.content._id
        })
      })
      .catch((error) => {
        this.setState({ showTable: true })
        console.log(error);
      });
  }


  render() {
    const { eventName, errorEventName, errorHostCountry } = this.state
    const { handleCancel} = this.props;
    return (
    <>
        <Row className="justify-content-sm-center">
            <Col xs sm={10} md={6} lg={6} className="pl-5 pr-5 pb-2 pt-5">
                <InputGroup>
                <FormControl className={errorEventName ? 'input-evtname error' : 'input-evtname'} placeholder="Event's name" value={eventName} onFocus={() => this.setState({ errorEventName: false })} onChange={(event) => this.setState({ eventName: event.target.value })} />
                </InputGroup>
                {errorEventName ? <span style={{ color: 'white', display: 'inline-flex', alignItems: 'center', paddingLeft: '20px', height: '25px' }}>* Event's name is required </span> : ''}
            </Col>
        </Row>
        <Row className="justify-content-sm-center">
            <Col xs sm={10} md={6} lg={6} className="pl-5 pr-5 pb-2 pt-2">
                <SelectOffice type="select" error={errorHostCountry}
                    handleChange={this.handleSelect}
                    handleFocus={this.handleSelectFocus}
                ></SelectOffice>
            </Col>
        </Row>
        <Row className="justify-content-sm-center">
            <Col xs sm={10} md={6} lg={6} className="pl-5 pr-5 pb-2 pt-2 text-center">
                <SelectTime type="create" handleOK={this.handleDateOK}></SelectTime>
            </Col>
        </Row>
        <Row className="justify-content-sm-center">
        <Col xs sm={10} md={6} lg={6} className="pl-5 pr-5 pb-2 pt-2 text-center">
            <SelectOffice type=""
                    selectedOffices={this.state.selectedOffices}
                    error={errorHostCountry}
                    handleChange={this.handleTreeSelect}
             ></SelectOffice>

        </Col>
        </Row>
        <Row className="justify-content-sm-center">
        <Col xs sm={10} md={6} lg={6} className="butt-cont-2 pl-5 pr-5 pb-2 pt-2">
            <Button type="primary" onClick={() => handleCancel() }>Cancel</Button>
            <Button type="primary" onClick={() => this.createRoom()}>Create</Button>
        </Col>
        </Row>
    </>
    )
  }
}

export { CreateEvent }
