import React from 'react';
import { Container } from 'react-bootstrap';
import { Button } from 'antd';

import './style.scss';

import { CreateUser } from '../dashboard/Users/components/CreateUser';


class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem('security'),
            confirmDirty: false,
            password: undefined,
            confirm: undefined,
            showForm: true,
            showMessage: false,
        };
        this.cancelForm = this.cancelForm.bind(this);
        this.handleCreate = this.handleCreate.bind(this);

    }


    formRef = React.createRef();

    handleCreate = () => {
        this.setState({ showForm: false, showMessage: true });
    };

    cancelForm = () => {
        this.props.history.push('/dashboard');
    };


    render() {
        const { showForm, showMessage } = this.state;
        return (
            <Container className="register">
                { showForm &&
                <CreateUser
                    profile={ this.props.profile }
                    type={ 'register' }
                    cancelCallback={ this.cancelForm }
                    createCallback={ this.handleCreate }

                />
                }

                { (showMessage && !this.props.isProfile) &&
                <p className="message">
                    Done!<br/>You will receive a confirmation email to complete your registration.<br/><br/>
                    <Button type="primary" className="login-form-button"
                            onClick={ () => this.props.history.push('/login') }>
                        Login
                    </Button>
                </p>
                }
            </Container>
        );
    }
}

export { RegisterForm };
