import React from 'react'
import { Link } from "react-router-dom"
import { Layout, Menu } from 'antd'
import { ScheduleOutlined, TeamOutlined, BuildOutlined, ApartmentOutlined, CalendarOutlined } from '@ant-design/icons';
import axios from 'axios';

import '../../../styles/dashboard/dashboard-main.scss'
const { SubMenu } = Menu
const { Sider } = Layout

class SliderMenu extends React.Component {
  state = {
    token: localStorage.getItem('security'),
    profile: {
      fullname: '',
      username: '',
      email: '',
      rol: '',
    }
  }

  componentDidMount() {
    axios.get('/api/v1/auth/profile', {
      headers: {
        'Authorization': `Bearer ${this.state.token}`,
      }
    }).then((response) => {
      this.setState({ profile: response.data.content.user })
    }).catch((error) => {
      // handle error
      console.log(error);
    })
  }

  render () {
    return <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={broken => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
      width={200} style={{ background: '#fff' }}
    >
      <Menu
        mode="inline"
        //defaultSelectedKeys={[props.selectedMenu]}
        defaultOpenKeys={['manage1', 'event1']}
        style={{ height: '100%' }}
      >
        {this.state.profile.rol === 'admin' &&
        <SubMenu
          key="manage1"
          title={
            <span>
              <ScheduleOutlined />
              Manage
            </span>
          }
        >
          {<Menu.Item key="1">
            <TeamOutlined />
            <Link to="/dashboard/users" style={{ display: 'inline-block' }}>Users</Link>
          </Menu.Item>}
          <Menu.Item key="2">
            <BuildOutlined />
            <Link to="/dashboard/business" style={{ display: 'inline-block' }}>Business office</Link>
          </Menu.Item>
        </SubMenu>}
        <SubMenu
          key="event1"
          title={
            <span>
              <ApartmentOutlined />
              Events
            </span>
          }
        >
          <Menu.Item key="5">
            <CalendarOutlined />
            <Link to="/dashboard/events" style={{ display: 'inline-block' }}>My Events</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  }
}
export { SliderMenu }
