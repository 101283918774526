import React from 'react'
import axios from 'axios';

import {Table,Divider, message } from 'antd';
import { Button,  Select, Modal, Layout } from 'antd';
import {EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Row, Col, InputGroup, FormControl } from 'react-bootstrap';

import '../../../../styles/dashboard/dashboard-main.scss';
import { StatusIdea } from '../../common/Utils';

const { Content } = Layout
const { confirm } = Modal
const { Option } = Select

class ListIdeas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  token: localStorage.getItem('security'),
	  visible: false,
	  loading: false,
	  editIdeaName: '',
	  ideaName: '',
	  selectedOffice: "",

      columns: [
		{
			title: 'Idea name',
			dataIndex: 'name',
			key: 'name',
			sorter:	(a, b) => a.name.localeCompare(b.name),
			render: (name) => (
				<span  style={{ textAlign: 'left',whiteSpace: 'normal' }}>{name}</span >
			)
		},
		{
			title: 'Office',
			dataIndex: 'office',
			key: 'office',
			sorter:	(a, b) => a.office.localeCompare(b.office),
		},
		{
			title: 'Score',
			dataIndex: 'score',
			key: 'score',
			sorter:	(a, b) => a.score? a.score - b.score: 0,
			render: (score) => score ? parseFloat(score).toFixed(2): parseFloat(0).toFixed(2)
		},
		{
			title: 'Votes',
			dataIndex: 'votes',
			key: 'votes',
			render: (votes) => votes? votes: 0
		},
		{
			title: 'State',
			dataIndex: '',
			key: 'state',
			render: (idea) => (
				<div>
					{
						idea != null && idea.state != null && idea.state == 'created' &&
						<Button color="green" onClick={() => this.sendToVotation(idea)} data-state={idea.state}>
							{StatusIdea(idea.state)}
						</Button>
					}
					{
						idea != null && idea.state != null && idea.state == 'votation' &&
						<Button id="close_votation" onClick={() => this.closeVotation(idea)} data-state={idea.state}>
							{StatusIdea(idea.state)}
						</Button>
					}
					{
						idea != null &&
						idea.state != null &&
						idea.state != 'created' &&
						idea.state != 'votation' &&
						StatusIdea(idea.state)
					}
				</div>
			)
		},
		{
			title: 'Action',
			dataIndex: '',
			key: 'x',
			render: (idea) => (
				<span>
					{idea != null && idea.state !=null && idea.state == 'created' &&
						<EditOutlined onClick={() =>
							this.setState({ visible: true, ideaToEdit: idea, editIdeaName: idea.name })
						} />}
						<Divider type="vertical" />
					{idea != null && idea.state !=null && idea.state == 'created' &&
						<DeleteOutlined onClick={()=> this.deleteIdea(idea)}/>}
				</span>
			)
		},
		]
	}
	this.deleteIdea = this.deleteIdea.bind(this);
  }

  componentDidMount() {
    //this.getRooms()
  }


  handleSelect = value => {
	this.setState({ selectedOffice: value })
	};

  saveIdea() {
	const { ideaName, selectedOffice} = this.state
	const {room} = this.props
	if (ideaName == '' || selectedOffice == '') return null
	let ideas = room.ideas;
	ideas.push({
		name: ideaName,
		office: selectedOffice
	});

	const data = {
		_id: room._id,
		ideas: ideas
	}

	axios.put("/api/v1/room/", data, {
		headers: {
			'Authorization': `Bearer ${this.state.token}`,
		}
	}).then((response) => {
		let room = response.data.content;
		room.clients = room.clients.sort((a, b) => a.state.localeCompare(b.state) * -1).map((rw, i) => Object.assign(rw, { number_log: i + 1 }));

		if(response.data.status !== 400) {
			this.props.handleUpdate(response.data.content)
		}

		this.setState({ ideaName: '' });

	}).catch((error) => {
		console.log(error);
	});
}


sendToVotation(idea) {
	const { socket, room } = this.props;
	let is_open = false

	room.ideas.map((idea)=>{
		if (idea.state == 'votation') {
			is_open = true
			return null
		}
	})

	if (is_open) {
		message.error('Close the current idea before sending a new one.')
		return false
	}
	if (document.querySelector(".access-code b")) {
		message.error('You must generate code.')
		return false
	}

	this.setState({showReload: true})

	socket.emit('open_votation', {
		access: this.state.token,
		ideaId: idea._id,
		room: room._id
	})
}

closeVotation(idea) {
	const { socket, room } = this.props;

	socket.emit('close_votation', {
		access: this.state.token,
		ideaId: idea._id,
		room: room._id
	})

	this.setState({showReload: false})
}

editIdea = value => {
	const {room } = this.props;

	if (this.state.editIdeaName=='') return null
	let ideas = [], idea = {};
	room.ideas.map((k, i) => {
		if (this.state.ideaToEdit._id == k._id) {
			idea.name = this.state.editIdeaName
			idea.office = k.office
			ideas.push(idea)
		} else {
			ideas.push(k)
		}

	})

	const data = {
	_id: room._id,
		ideas: ideas
	}

	axios.put("/api/v1/room/", data, {
		headers: {
			'Authorization': `Bearer ${this.state.token}`,
			}
		}).then((response) => {

			if(response.data.status !== 400) {
				this.props.handleUpdate(response.data.content)
			}
			this.setState({visible: false, loading: false, ideaToEdit: null, editIdeaName: ''})

		}).catch((error) => {
			console.log(error);
		});
}

deleteIdea(idea) {
	const {room} = this.props;
	const _this = this;

	confirm({
	  title: 'Are you sure you want to delete this idea?',
	  //content: 'Some descriptions',
	  okText: 'Yes',
	  okType: 'danger',
	  cancelText: 'No',
	  onOk() {
		let ideas = [];
		room.ideas.map((k, i) => {
			if(idea._id !== k._id)
				ideas.push(k);
			return i;
		})

		const data = {
			_id: room._id,
				ideas: ideas
			}

			axios.put("/api/v1/room/", data, {
				headers: {
					'Authorization': `Bearer ${_this.state.token}`,
				}
			}).then((response) => {

				if(response.data.status !== 400) {
					_this.props.handleUpdate(response.data.content)
				}

			}).catch((error) => {
				console.log(error);
			});
		 },
	  onCancel() {
			console.log('Cancel');
	  },
	});
	}

	// MODAL CANCEL
	handleCancel () {
		this.setState({visible: false, loading: false, ideaToEdit: null, editIdeaName: ''})
	}

  render() {
    const {room, ideas} = this.props
	const {visible, loading, ideaName, editIdeaName, selectedOffice} = this.state
    return (
      <>
 		<Col className="ideas-section p-0 pt-2" xs={12} sm={12} md={12} lg={12}>
			<Table
				columns={this.state.columns}
				dataSource={ideas}
				rowKey={ideas => ideas._id}
				className='table-ideas-container'
				pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['25', '50', '100']}}
			/>
		</Col>
		<br></br>
		<Content className="add-idea-container">
			<Row className="justify-content-sm-center">
				<Col xs={5} sm={5} md={5} lg={5} className="pl-0 pr-3" >
					<InputGroup>
						<FormControl placeholder="Write an idea" value={ideaName} onChange={(event) => this.setState({ ideaName: event.target.value })} />
					</InputGroup>
				</Col>
				<Col xs={5} sm={5} md={5} lg={5} className="pl-3 pr-0">
					<Select className="select-office" placeholder="Select Office" style={{ width: '100%' }} onChange={this.handleSelect}>
						{room != null && room.offices != null &&
							room.offices.map((office, i) => {
								return (
									<Option value={office.name} key={i}>{office.name}</Option>
								)
							})}
					</Select>
				</Col>
				<Col xs={2} sm={2} md={2} lg={2} className="pr-3 pl-3">

				{selectedOffice.length > 0 &&
					<PlusCircleOutlined onClick={() => this.saveIdea()} />
				}
				</Col>
			</Row>
		</Content>

		<Modal
			visible={visible}
			title="Edit idea title"
			onOk={this.editIdea}
			onCancel={()=> this.handleCancel()}
			footer={[
				<Button key="back" onClick={()=> this.handleCancel()}>
				Cancel
				</Button>,
				<Button key="submit" type="primary" loading={loading} onClick={()=> this.editIdea()}>
				Save
				</Button>,
			]}
			>
			<InputGroup>
				<FormControl placeholder="Idea title" value={editIdeaName} onChange={(event) => this.setState({ editIdeaName: event.target.value })} />
			</InputGroup>
		</Modal>
      </>

    )
  }
}

export { ListIdeas }
