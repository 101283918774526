import React from 'react';
import {Form, Input, Button, message } from 'antd';

import {
  passwordValidationErrorMessage,
  fullNameError,
  emailError,
  userNameError,
  passwordError,
  confirmPaswordError} from './config';

import '../../../../styles/dashboard/dashboard-main.scss';

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state =  {
      token: localStorage.getItem('security'),
      password: undefined,
      confirm: undefined,
      confirmDirty: false,
      formFull: true,
      formFields: false,
      message: "",
      title: "Create",
      editUserId: undefined,
      users: [],
      messages: {

      }
    }
    this.onFinish = this.onFinish.bind(this);

  }

  handleConfirmBlur = e => {
    const { password, confirm } = this.state
    if (password !== confirm) {
      message.error(passwordValidationErrorMessage)
      this.setState({ confirmDirty: true });
    } else {
      this.setState({ confirmDirty: false });
    }
  };


  onFinish(values){
    const { confirm, password } = this.state

    if (password && (password !== confirm)) {
      message.error(passwordValidationErrorMessage)
      return false;
    }

    this.props.onFinish(values);
  }

  render() {
    const { confirm, password, message } = this.state
    const {onCancel, type, profile, user} = this.props
    const isProfile = type === "profile" ? true: false;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };


    return (
        <>
          <Form
          // {...formItemLayout}
          ref={this.formRef}

          {...(type !== "edit" && type !== "create"  && { size:"small", layout:"vertical" })}
          {...((type === "edit" || type === "create") && { ...formItemLayout })}

          onFinish={this.onFinish}
          className={`${ type !== 'register'  ? "create-user-form": "login-form"}`}>

            {!isProfile &&
              <Form.Item>
                {(type === "edit" || type === "create") && <h4>{ user ? "Edit User" : "Create User"}</h4>}
                <h4>{(type !== "edit" && type !== "create") && type.charAt(0).toUpperCase() + type.slice(1)}</h4>
              </Form.Item>
             }

            <Form.Item name="fullname" label="Fullname" rules={[{required: isProfile || user ? false:true, message: fullNameError}]}>
              <Input
                placeholder="John Doe"
                autoComplete={isProfile || user ? "on":"new-password"}
                defaultValue={profile ? profile.fullname : user ? user.fullname :''}
              />

            </Form.Item>

            {(!user || isProfile ) &&
              <Form.Item name="username" label="Username" rules={[{required: isProfile?false:true, message: userNameError}]}>
                <Input placeholder="jdoe_9_12_18" disabled={isProfile? true: false} autoComplete={user?"on":"new-password"} defaultValue={user? user.username:''}/>
              </Form.Item>
            }

            <Form.Item name="email" label="E-mail"
              rules={[{type: 'email', message: 'The input is not valid E-mail!' },{required: isProfile || user ? false : true, message: emailError}]}>
              <Input
              placeholder="john.doe@vml/@vml.com"
              autoComplete={isProfile || user ?"on":"new-password"}
              defaultValue={profile ? profile.email : user ? user.email :''}/>
            </Form.Item>

            {(isProfile) &&
            <Form.Item name="old_password" label="Old Password" rules={[{required: isProfile?false:true,message: passwordError}]}>
              <Input.Password className={password === confirm?'':'error'} onChange={(e)=> this.setState({password: e.target.value})} autoComplete="new-password" />
            </Form.Item>}

            {(!user || isProfile ) &&
            <Form.Item name="password" label="Password" rules={[{required: isProfile?false:true,message: passwordError}]}>
              <Input.Password className={password === confirm?'':'error'} onChange={(e)=> this.setState({password: e.target.value})} autoComplete="new-password" />
            </Form.Item>
            }

            {(!user || isProfile ) &&
            <Form.Item label="Confirm Password" rules={[{required: isProfile?false:true,message: confirmPaswordError}]}>
              <Input.Password className={password === confirm?'':'error'} onChange={(e)=> this.setState({confirm: e.target.value})} onBlur={this.handleConfirmBlur} autoComplete={this.props.profile?"on":"new-password"} />
            </Form.Item>
            }

            <div style={{ display: 'flex',  justifyContent: `${(type !== "edit" && type !== "create") ? 'space-between' : 'end'}`}}>

              <Button type="primary" htmlType="submit" className={`login-form-button ${(type === "edit" || type === "create") && 'mr-2'}`}>
                {(type !== "register" && type !== "edit" && type !== "create" ) ? (user || profile) ? "Edit" : "Create" : ""}
                {!isProfile && type && type.charAt(0).toUpperCase() + type.slice(1)}

              </Button>
              {!isProfile &&
                <Button type="primary" className="login-form-button" onClick={()=> onCancel()}>
                  Cancel
                </Button>
                }
            </div>

          </Form>

          {message!== "" ? message:''}
        </>
    )
  }
}

export { UserForm }
