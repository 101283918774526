import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Button, Radio } from 'antd';
import { InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { Waiting } from '../index';

import 'antd/dist/antd.css';
import './style.scss';

import hints from './hints.json';
import { colors } from '../../../services/theme.json';

class Votation extends React.Component {
  state = {
    fireValue: 0,
    inspireValue: 0,
    relevanceValue: 0,
    effectValue: 0,
    titleHint: "",
    subtitleHint: "",
    descriptionHint: "",
    showHint: false,
    options: ["fire", "inspire", "relevance", "effect"], // options of question
    waitingForVote: "room", // "before" => waiting for the first time | "after" => waiting post vote
    ideaNumber: 0,
    idea: this.props.idea,
    access: localStorage.getItem('token'),
    socket: this.props.socket
  }

  showIdea = () => {
    const { options, idea, showHint, titleHint, subtitleHint, descriptionHint } = this.state

    return (

      <Container className="votation full-viewport background-mesh">
        {showHint &&
          <div className="popupHint">
            <CloseCircleOutlined className="closeHint" onClick={()=>this.setState({showHint: false})}/>
            <b className="titleHint">{titleHint}</b><br />
            <span className="subtitleHint">{subtitleHint}</span><br /><br />
            <p className="descriptionHint" dangerouslySetInnerHTML={{__html: descriptionHint}}></p><br />
          </div>
        }
        <Row className="justify-content-sm-center">
          <Col xs sm={10} md={6} lg={4} className="pt-3 pb-3 head">
            <div className="box">
              <p className="white mb-3 text-center">Ideas of &nbsp;<b>{idea?.office}</b></p>
              <p className="gray text-center">{(idea?.name.length > 100) ? `${idea.name.slice(0,100)} ...` : idea.name}</p>
            </div>
          </Col>
        </Row>

        {
          options.map((option, idx) => {
            const questionClassName = "question question" + idx;

            return (
              <div className={questionClassName} key={`row-${idx}`}>
                <Row className="justify-content-sm-center">
                  <Col xs sm={10} md={6} lg={4} className="vote pt-0 pb-0 pl-2 pr-2">
                    <InfoCircleOutlined style={{ color: colors.white }} onClick={()=>this.openHint(option)}/>
                    <h3 className="white mb-0" style={{ color: this.getColorFork(idx) }}>{option.slice(0,1)}</h3>
                    <Radio.Group
                      name={option}
                      onChange={this.onChange}
                      value={this.getValue(option)}
                    >
                      <Radio.Button value={0} >0</Radio.Button>
                      <Radio.Button value={1} >1</Radio.Button>
                      <Radio.Button value={2}>2</Radio.Button>
                      <Radio.Button value={3}>3</Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
            )
          })
        }
        <Row className="justify-content-sm-center mb-4 mt-4">
          <Col xs sm={10} md={6} lg={4} className="text-center">
            <span className="text-points white mb-0">Your vote total - <b>{this.getPoints()} points</b></span>
          </Col>
        </Row>
        <Row className="justify-content-sm-center" >
          <Col xs sm={10} md={6} lg={4} className="pb-3 text-center">
            <Button size="large" className="btn-orange" onClick={this.onVote}>Vote!</Button>
          </Col>
        </Row>
      </Container>
    )
  }

  getColorFork = (index) => {
    switch (index) {
      case 0: return colors.yellow
      case 1: return colors.brightOrange
      case 2: return colors.strongBlue
      case 3: return colors.brightPink
      default: return colors.brightPink
    }
  }

  getBgRadioGroup = (index) => {
    switch (index) {
      case 0: return colors.veryDarkDesaturatedMagenta
      case 1: return colors.veryDarkDesaturatedOrange
      case 2: return colors.veryDarkBlue
      case 3: return colors.eBackgroundColor
      default: return colors.veryDarkDesaturatedMagenta
    }
  }

  openHint = (group) => {
    this.setState({showHint: true})

    this.setState({
      titleHint: hints[`${group}`].title,
      subtitleHint: hints[`${group}`].subtitle,
      descriptionHint: hints[`${group}`].description
    })
  }

  getValue = (group) => {
    const { fireValue, inspireValue, relevanceValue, effectValue } = this.state
    switch (group) {
      case "inspire": return inspireValue
      case "relevance": return relevanceValue
      case "effect": return effectValue

      default: return fireValue // fire
    }
  }

  onChange = (event) => {
    const name = event.target.name ? event.target.name : ""
    switch (name) {
      case "inspire":
        this.setState({ inspireValue: event.target.value })
        break
      case "relevance":
        this.setState({ relevanceValue: event.target.value })
        break
      case "effect":
        this.setState({ effectValue: event.target.value })
        break
      default: this.setState({ fireValue: event.target.value })  // fire
    }
  }

  getPoints = () => {
    const { fireValue, inspireValue, relevanceValue, effectValue } = this.state

    return fireValue + inspireValue + relevanceValue + effectValue
  }

  onVote = () => {
    if (!window.navigator.onLine) {
      return false
    }
    const {socket, fireValue, inspireValue,
       relevanceValue, effectValue, access, idea} = this.state;

    const intention = {
      ideaId: idea._id,
      vote: {
        f: fireValue,
        i: inspireValue,
        r: relevanceValue,
        e: effectValue
      },
      access
    }

    this.setState({ fireValue: 0,inspireValue: 0,relevanceValue: 0,effectValue: 0})
    socket.emit('vote', intention);
  }


  render () {
    const { socket } = this.state;
    document.body.classList.remove('scroll');

    switch (socket) {
      case "room":
        this.simulationToWaitingEnterRoom()
        return <Waiting  entry={1} />
      case "before":
        this.simulationToWaitingNewQuestion()
        return <Waiting  entry={2} />
      case "after":
        this.simulationToWaitingAfterVote()
        return <Waiting  entry={3} />
      default:
        return this.showIdea()
    }
  }
}
export { Votation }
