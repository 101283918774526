import React from 'react'

import {Table, Popconfirm, message} from 'antd';
import { Button,  Modal, Layout } from 'antd';

import '../../../../styles/dashboard/dashboard-main.scss';

const { Content } = Layout

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewResults:false,
      token: localStorage.getItem('security'),
      columns: [
        {
          title: 'Idea name',
          dataIndex: 'name',
          sorter:	(a, b) => a.name.localeCompare(b.name),
          render: (name) => (
            <span  style={{ textAlign: 'left',whiteSpace: 'normal' }}>{name}</span >
          )
        },
        {
          title: 'Office',
          dataIndex: 'office',
          sorter:	(a, b) => a.office.localeCompare(b.office),
        },
        {
          title: 'Score',
          dataIndex: 'score',
          defaultSortOrder: true,
          sorter:	(a, b) => a.score? a.score - b.score: 0,
          render: (score) => score? parseFloat(score).toFixed(2).toLocaleString():parseFloat(0).toFixed(2).toLocaleString()
        },
        {
          title: 'F',
          dataIndex: 'f',
          sorter:	(a, b) => b.f - a.f,
          render: (f) => f? parseFloat(f).toFixed(2).toLocaleString():'0.00'
        },
        {
          title: 'I',
          dataIndex: 'i',
          sorter:	(a, b) => b.i - a.i,
          render: (i) => i? parseFloat(i).toFixed(2).toLocaleString():'0.00'
        },
        {
          title: 'R',
          dataIndex: 'r',
          sorter:	(a, b) => b.r - a.r,
          render: (r) => r? parseFloat(r).toFixed(2).toLocaleString():'0.00'
        },
        {
          title: 'E',
          dataIndex: 'e',
          sorter:	(a, b) => b.e - a.e,
          render: (e) => e? parseFloat(e).toFixed(2).toLocaleString():'0.00'
        },
      ]
    }
  }

  componentDidMount() {
    //this.getRooms()
  }


  exportResults() {
    const {room} = this.props;

		let is_open = false
		let has_no_votes = true

    room.ideas.map((idea)=>{
			if (idea.state == 'votation') {
				is_open = true
				return null
			}
			if (idea.votes > 0) {
				has_no_votes = false
			}
		})

		if (is_open) {
			message.error('You must close the open votation.')
			return false
		}

		if (has_no_votes) {
			message.error('You don\'t have any votes to export.')
			return false
		}

		// https://github.com/axios/axios/issues/1660
		const url= "/api/v1/vote/report/" + room._id;
		fetch(url, {
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
			  'content-type': 'application/vnd.ms-excel;charset=UTF-8',
			  'Authorization': `Bearer ${this.state.token}`,
			},
			method: 'GET' // *GET, POST, PUT, DELETE, etc.
		  })
		  .then(res => res.blob().then(blob => {
			const filename = decodeURI(res.headers.get('Content-Disposition').split('filename=')[1])
			if (window.navigator.msSaveOrOpenBlob) {
			  navigator.msSaveBlob(blob, filename)
			} else {
			  const a = document.createElement('a')
			  document.body.appendChild(a)
			  a.href = window.URL.createObjectURL(blob)
			  a.download = filename
			  a.target = '_blank'
			  a.click()
			  a.remove()
			  window.URL.revokeObjectURL(url)
			}
		  }))
	}

	previewResults () {
		if (this.state.previewResults) {
			this.setState({previewResults: false})
		} else {
			this.setState({previewResults: true})
		}
	}

	showResults() {
		const { socket, room} = this.props;
		let is_open = false
		let has_no_votes = true

		room.ideas.map((idea)=>{
			if (idea.state == 'votation') {
				is_open = true
				return null
			}
			if (idea.votes > 0) {
				has_no_votes = false
			}
		})

		if (is_open) {
			message.error('You must close the open votation.')
			return false
		}

		if (has_no_votes) {
			message.error('You don\'t have any votes to show.')
			return false
		}

		if (document.querySelector(".access-code b")) {
			message.error('You must generate code.')
			return false
		}

		socket.emit('show_votation', {
			access: this.state.token,
			room: room._id
		})
	}

  render() {
    const {data} = this.props

    return (
      <>
      <Content className="showResultsContainer">
        <Button type="primary" onClick={()=> this.previewResults()}>Preview results</Button>
        <Popconfirm placement="topLeft" title='Are you sure?' onConfirm={() => this.showResults()} okText="Yes" cancelText="No">
          <Button type="primary">Show results</Button>
        </Popconfirm>
        <Popconfirm placement="topLeft" title='Are you sure?' onConfirm={() => this.exportResults()} okText="Yes" cancelText="No">
          <Button type="primary">Export results</Button>
        </Popconfirm>
      </Content>

      <Modal
					visible={this.state.previewResults}
					title="Preview Results"
					onOk={()=> this.previewResults()}
					onCancel={()=> this.previewResults()}
					className="modal-preview"
					>
					<Table
						rowKey={ideas => ideas._id}
						columns={this.state.columns}
						dataSource={data}
						bordered
						className='table-ideas-container'
					/>
				</Modal>
      </>

    )
  }
}

export { Results }
