import moment from 'moment';

const timeValidationError =  "Event must be after the creation time, and have at least 1 hour duration."

const validateStart = (notBefore, origNotBefore) => {
  let correctStart = moment(notBefore).isSameOrAfter(moment()) // min validation for start is current time 

   // FOR UPDATE  
   if(origNotBefore) {
    correctStart = moment(notBefore).isSameOrAfter(origNotBefore) // Already validated in creation that it was after the current creation time
  }

  if(correctStart) return true
  return false
}

const validateEnd = (notAfter, notBefore) => {
  let correctEnd = moment(notAfter).isSameOrAfter(moment(notBefore).add(1, "hour"));  

  if(correctEnd) return true
  return false
}

const validateFullDate = (notBefore, notAfter, origNotBefore) => {
  const correctStart = validateStart(notBefore, origNotBefore);  
  const correctEnd = validateEnd(notAfter, notBefore);  

  if(correctStart && correctEnd) return true
  return false;
}

const validateDateRange = (notBefore, notAfter) => { 
  let inRange = {
    start: false, 
    end: false
  }
  const correctStart = moment().isSameOrAfter(moment(notBefore)); // current time is Afetr the not notBefore
  const correctEnd = moment().isSameOrBefore(moment(notAfter));// current time is Before the not notAfter
 
  if(correctStart) inRange.start = true;
  if(correctEnd) inRange.end = true;
  
  return inRange
}

const onDateOk = (e, room, callback) => {

  const notBefore = e[0];
  const notAfter = e[1];
  let origNotBefore = false;

  if(room){
    origNotBefore = room && room.createdAt ? room.createdAt : false
  }
  
  let valid = false;

  if (notBefore != null && notAfter != null) {
    if (validateStart(notBefore, origNotBefore ) && validateEnd(notAfter, notBefore )) valid = true;
    
    callback(notBefore, notAfter, valid); 
  }
}


// const range = (start, end) => {
// 	const result = [];
// 	for (let i = start; i < end; i++) {
// 	  result.push(i);
// 	}
// 	return result;
// }

// const disabledRangeTime = (_, type) => {
//   if (type === 'start') {
//     if(moment().isSame(_, 'date')){
//      return {
//         disabledHours: () => range(0, 60).splice(0, moment().hours()),
//       };
//     }
//   }

//   if (moment().add(1, 'day').isSameOrAfter(_)) {
//     return {
//       disabledHours: () => range(0, 60).splice(0, moment().hours()),
//     }
//   }

//   return {
//     disabledHours: () => false,
//     disabledMinutes: () => false,
//   };
// }


const disabledDate = (current, room) => {
  const origNotBefore = room && room.createdAt ? moment(room.createdAt) : moment();  
  if( current.isSame(origNotBefore, 'date')) return false
  return current && moment(current) <= origNotBefore;
 }

export {
  validateStart,
  validateEnd, 
  validateFullDate,
  validateDateRange,
  onDateOk,
  disabledDate,
  timeValidationError
};