import React from 'react';
import { isMobile, isAndroid } from 'react-device-detect';
import { Container, Row, Col, Image, InputGroup, FormControl } from 'react-bootstrap';
import { Button } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import fire from '../../../images/fire.png';
import logo from '../../../images/vml-logo-white.png';

import './style.scss';

class Auth extends React.Component {
  state = {
    code: "",
    nickname: "",
    errorCode: false,
    errorNickname: false,
    socket: this.props.socket,
    access: localStorage.getItem('token'),
    showAuth: true
  }

  componentDidMount() {
    if (this.state.access != null){
      this.setState({showAuth: false});
    }
  }

  componentWillReceiveProps(nextProps){
    console.log(nextProps)
    if(nextProps.errorCode!==this.props.errorCode){
      //Perform some operation
      this.setState({errorCode: this.props.errorCode});
    }
  }

  signIn = () => {
    let { code, nickname, access, socket } = this.state
    let errorC = false
    let errorN = false
    if (nickname === '') {
      this.setState({errorNickname: true})
      errorN = true
    }
    if (code === '') {
      this.setState({errorCode: true})
      errorC = true
    }
    if (errorC || errorN) {
      return null
    }
    nickname = nickname.replace(/\s/g, "_");
    socket.emit('sign_in', {
      token: code, nickname, access,
    });
  }

  onFocusCode = () => {
    if (isMobile && isAndroid) {
      document.body.classList.add('scroll');
    }

    this.setState({ errorCode: false });
  }

  onFocusNickname = () => {
    if (isMobile && isAndroid) {
      document.body.classList.add('scroll');
    }

    this.setState({ errorNickname: false })
  }

  enterCode = () => {
    const { code, errorCode, nickname, errorNickname } = this.state
    return (
      <div className="auth-container">
        <Row className="justify-content-sm-center">
          <Col xs sm={10} md={6} lg={4} className="pb-3 text-center">
            <Image style={{ width: '70px' }} src={fire} round="true" fluid />
          </Col>
        </Row>

        <div className="justify-content-sm-center">
            <Row className="justify-content-sm-center">
              <Col xs sm={10} md={6} lg={4} className="pb-3">
                <p className="title text-center pr-3 pl-3">To participate enter <br />the voting code</p>
                <InputGroup>
                  <FormControl
                    type='tel'
                    className={errorCode? 'error_code white_input' : 'white_input'}
                    maxLength="6"
                    placeholder="code"
                    value={code}
                    onChange={(event) => this.setState({ code: event.target.value, errorCode: false })}
                    onFocus={this.onFocusCode}
                    onBlur={() => document.body.classList.remove('scroll')}
                  />
                  {errorCode?<WarningOutlined className="warningError" />:''}
                </InputGroup>
                {errorCode?<span style={{color: 'white',display: 'inline-flex', alignItems: 'center', paddingLeft: '20px', height: '25px'}}>* code is required </span>:''}
              </Col>
            </Row>

            <Row className="justify-content-sm-center">
              <Col xs sm={10} md={6} lg={4} className="pb-0 pt-3">
                <p className="title text-center pr-3 pl-3">Enter your name</p>
                <InputGroup>
                  <FormControl
                    placeholder="your name"
                    className={errorNickname? 'error_code white_input' : 'white_input'}
                    value={nickname}
                    onChange={(event) => this.setState({ nickname: event.target.value })}
                    onFocus={this.onFocusNickname}
                    onBlur={() => document.body.classList.remove('scroll')}
                  />
                  {errorNickname?<WarningOutlined className="warningError" />:''}
                </InputGroup>
                {errorNickname?<span style={{color: 'white',display: 'inline-flex', alignItems: 'center', paddingLeft: '20px', height: '25px'}}>* name is required </span>:''}
                <br /><br />
              </Col>
            </Row>

            <Row className="justify-content-sm-center pb-3">
              <Col xs sm={10} md={6} lg={4} className="p-1 text-center">
                <Button type="primary" size="large" className="btn-orange mt-2 mb-2" onClick={() => this.signIn()}>Start</Button>
              </Col>
            </Row>

        </div>
      </div>
    )
  }

  render() {

    return (
      <Container className="auth full-viewport background-mesh">
        {this.state.showAuth ? this.enterCode() : ""}
      </Container>
    )
  }
}

export { Auth }
