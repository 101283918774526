import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import { Button } from 'antd';

import warning from '../../../images/warning.png';

import './style.scss';

class Error extends React.Component {

  state = {
    roomNotFound: this.props.clearToken ? true : false,
    socket: this.props.socket
  }


  // resetSession(){
  //   const socket = this.props.socket();
  //   console.log('reset session')
  //   localStorage.removeItem('token');
  //   socket.disconnect();
  //   socket.connect();
  //   window.location.reload();
  // }
  onTryAgain = () => {
    if(this.state.roomNotFound) {
      this.props.onResetSession(this.state.socket)
    } else {
      window.location.reload()
    }
  }

  render() {
    document.body.classList.remove('scroll');
    console.log(this.props.clearToken);

    return (
      <Container className="error full-viewport background-mesh">
        <div>
          <Row className="justify-content-sm-center">
            <Col xs sm={10} md={6} lg={4} className="p-1 text-center">
              <Image src={warning} round="true" fluid style={ { width: '75px' } } />
              <p className="title pt-5 pl-5 pr-5 text-center m-0">{this.props.message || 'Oops! Something went wrong.'}</p>

              <Button
                type="link"
                size="large"
                className="mt-4"
                onClick={this.onTryAgain}
                >
                  Try again!
                </Button>
            </Col>
          </Row>

          {!this.state.roomNotFound ?
          <Row className="justify-content-sm-center" >
              <Col xs sm={10} md={6} lg={4} className="pl-5 pr-5 pb-4 text-center">
                <Button type="link" className="mt-5 btn-leave" onClick={() => this.props.onResetSession(this.state.socket)}>Leave</Button>
              </Col>
            </Row>
          :""}
        </div>
      </Container>
    )
  }
}

export { Error }
