import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Button } from 'antd';

import './style.scss';
import { colors } from '../../../services/theme.json';


class Results extends React.Component {
  state = {
      socket: this.props.socket
  };

  render() {
    const { results } = this.props;
    if (results.length > 2) {
      document.body.classList.add('scroll');
    }

    return (
      <Container className="results full-viewport background-mesh">
        <Row className="justify-content-sm-center">
          <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="pt-3 pb-2">
            <div className="box">
              <h4 className="white mb-0 text-center">The Ranking</h4>
            </div>
          </Col>
        </Row>
        {
          results.map((res, idx) => {
            //const width = res.score / 12 * 100
            const width = 100;
            return (
              <Row className="justify-content-sm-center" key={ idx }>
                <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="p-4">

                  <h4 className="white mb-0">{res?.office}</h4>
                  <p className="white mb-0 mt-2 pb-2"><i>{ res?.name }</i></p>

                  <div className={ `row-graph p-2 ${ idx % 2 ? 'odd' : 'even' }` } style={ { width: `${ width }%` } }>
                    <span className="pl-2 pr-2 white">{ parseFloat(res.score).toFixed(2).toLocaleString() }</span>

                    { res.score > 0 &&
                      <div className="fire-score">
                        <div>
                          <h4 style={{ background: colors.yellow }} className="font-result white pl-1 pr-1 mb-1 mt-1">F</h4>
                          <b style={{ background: colors.yellow, width: `${ (res.f + 1) / 6 * 100 }%`}}>
                            { parseFloat(res.f).toFixed(2).toLocaleString() }
                          </b>
                        </div>

                        <div>
                          <h4 style={{ background: colors.brightOrange }} className="font-result white pl-1 pr-1 mb-1 mt-1">I</h4>
                          <b style={{ background: colors.brightOrange, width: `${ (res.i + 1) / 6 * 100 }%`}}>
                            { parseFloat(res.i).toFixed(2).toLocaleString() }
                          </b>
                        </div>

                        <div>
                          <h4 style={{ background: colors.strongBlue }} className="font-result white pl-1 pr-1 mb-1 mt-1">R</h4>
                          <b style={{ background: colors.strongBlue, width: `${ (res.r + 1) / 6 * 100 }%` }}>
                            { parseFloat(res.r).toFixed(2).toLocaleString() }
                          </b>
                        </div>

                        <div>
                          <h4 style={{ background: colors.brightPink }} className="font-result white pl-1 pr-1 mb-1 mt-1">E</h4>
                          <b style={{ background: colors.brightPink, width: `${ (res.e + 1) / 6 * 100 }%` }}>
                            { parseFloat(res.e).toFixed(2).toLocaleString() }
                          </b>
                        </div>
                      </div>
                    }
                  </div>
                </Col>
              </Row>
            );
          })
        }
        <Row className="justify-content-sm-center pb-5">
          <Col xs sm={ 10 } md={ 6 } lg={ 4 } className="pt-3 pb-5 pl-5 pr-5 text-center">
            <Button type="link" size="large" className="btn-leave mt-5" onClick={() => this.props.onResetSession(this.state.socket)}>Leave</Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export { Results };
