import React from 'react'

import {Table,Divider, Progress, Statistic, Row, Col} from 'antd';
import { LikeOutlined, DislikeOutlined, } from '@ant-design/icons';
// import { Row, Col } from 'react-bootstrap';
import { StatusClient } from '../../common/Utils';

import '../../../../styles/dashboard/dashboard-main.scss';

class ListClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewResults:false,
      token: localStorage.getItem('security'),
      columns: [
			{
				title: '#',
				dataIndex: 'number_log',
			},
			{
				title: 'Nickname',
				dataIndex: 'nickname',
				key: 'nickname',
				sorter:	(a, b) => a.nickname.localeCompare(b.nickname)
			},
			{
				title: 'State',
				dataIndex: 'state',
				key: 'state',
				sorter:	(a, b) => a.state.localeCompare(b.state) * -1 ,
				onFilter: (value, client) => client.state.includes(value),
				filters: [
					{ text: 'Entering', value: 'entering' },
					{ text: 'Enabled', value: 'enabled' },
					{ text: 'Voting', value: 'voting' },
					{ text: 'Voted', value: 'voted' },
					{ text: 'Disconnected', value: 'disconnected' },
					{ text: 'Disabled', value: 'disabled' },
				],
				render:(state) =>(
					<>
                    {StatusClient(state)}
                    </>
				)
			},
			{
				title: 'Action',
				dataIndex: '',
				key: 'x',

				render: (client) => (
					<span>
						{((client!= null && client!= null && client.state == 'entering') || (client!=null && client.state == 'disabled')) &&
                            <span>
								<LikeOutlined onClick={() => this.changeClientStatus(client, true)} data-key={client.state}/>
							</span>
						}
						{(client!= null && client.state !== 'disabled' && client.state !== 'entering') &&
							<span>
								<DislikeOutlined type="dislike" onClick={() => this.changeClientStatus(client, false)} data-key={client.state}/>
							</span>
						}
					</span>
				)
			},
		]
    }
  }

  componentDidMount() {
    //this.getRooms()
  }



	changeClientStatus(client, approve) {
        const { socket, room } = this.props;

		if (approve) {
			socket.emit('permit', {
				access: this.state.token,
				room: room._id,
				nickname: client.nickname
			});
		} else {
			socket.emit('deny', {
				access: this.state.token,
				room: room._id,
				nickname: client.nickname
			});
		}
	}

	disconnectClient(client, approve) {
		const { socket, room } = this.props;
		socket.emit('unbound', {
			access: this.state.token,
			room: room._id,
			nickname: client.nickname
		});
	}


  render() {
    const {room, voters} = this.props

    return (
      <>
        <Row gutter={[16, 16]} className="justify-content-sm-center">
            <Col className="disclaimer-container" span={4}>
                <div>
                    <LikeOutlined />
                    <Divider type="vertical" />
                    <span>Enable voter</span>
                </div>
                <div>
                    <DislikeOutlined />
                    <Divider type="vertical" />
                    <span>Disable voter</span>
                </div>
            </Col>
            <Col span={4}>
                <Statistic title="Voters" value={voters.total} />
            </Col>
            <Col span={4}>
                <Statistic title="Online" value={voters.online} />
            </Col>
            <Col span={4}>
                <Statistic title="Entering" value={voters.entering} />
            </Col>
            <Col span={4}>
                <Statistic title="Offline" value={voters.offline} />
            </Col>
            <Col span={4}>
                <Statistic title="Disabled" value={voters.disabled} />
            </Col>
        </Row>
        <br></br>
        <Row gutter={[16, 16]} className="justify-content-sm-center">
            <Col span={20}>
                <Table
                    columns={this.state.columns}
                    dataSource={room.clients}
                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['25', '50', '100']}}
                    rowKey={client => client._id} />
            </Col>
            <Col span={4}>
                {(room.state=='votation')&&
                    <Col>
                        <br></br>
                        <h4>Votation</h4>
                        <Progress type="circle" percent={(voters.voted+voters.voting)==0?0:parseFloat(
                            100*voters.voted/(voters.voted+voters.voting)
                        ).toFixed(2)} />
                    </Col>
                }
            </Col>
        </Row>
      </>

    )
  }
}

export { ListClients }
