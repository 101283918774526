import React from 'react';
import { message } from 'antd';
import axios from 'axios';

import {emailValidationErrorMessage, successMessage, serverError} from './config';

import '../../../../styles/dashboard/dashboard-main.scss';
import { UserForm } from './userForm';

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('security'),
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = values => {
    const { fullname, username, email, password } = values;
    const { createCallback, type } = this.props;

    axios.post('/api/v1/auth/register', { fullname, username, email, password })
      .then((response) => {
        if (response.data.content === "Invalid domain") {
          message.error(emailValidationErrorMessage)
        } else {
          if(response.data.status !== 400) {
           // message.success(successMessage)
            if(type === 'create') message.success(successMessage);

            if(createCallback) createCallback();

          }
        }
      }).catch((error) => {
        message.error(serverError)
        console.log(error);
      })
  };


  render() {
    const {cancelCallback, type,} = this.props

    return (
        <UserForm type={type}
          onFinish={this.handleSubmit}
          onCancel={cancelCallback}
        ></UserForm>
    )
  }
}

export { CreateUser }
