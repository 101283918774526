import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import { Navbar, Nav } from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';

import { App, PageNotFound } from './components/frontuser';
import { Users, Business, Events, Event } from './components/dashboard';
import { LoginForm, ForgotForm, RegisterForm } from './components/login';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';


// > V5.1

const customHistory = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = localStorage.getItem('security');
    return (
        <Route
            { ...rest }
            render={ props =>
                isAuthenticated ? (
                    <Component { ...props } />
                ) : (
                    <Redirect
                        to={ {
                            pathname: '/login',
                            state: { from: props.location }
                        } }
                    />
                )
            }
        />
    );
};


ReactDOM.render(
    <Router history={ customHistory }>
        {/*
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand href="/votation">Votation</Navbar.Brand>
          <Nav className="mr-auto">
          <Nav.Link href="/dashboard">Dashboard</Nav.Link>
            <Nav.Link href="/login">Login</Nav.Link>
          </Nav>
        </Navbar>
        */ }

        <Switch>
            { /* APP (FRONT USER) */ }
            <Route exact path="/" component={ App }/>

            { /* DASHBOARD */ }
            <PrivateRoute path="/dashboard/users" component={ Users }/>
            <PrivateRoute path="/dashboard/business" component={ Business }/>
            <PrivateRoute path="/dashboard/events/:event" component={ Event }/>
            <PrivateRoute path="/dashboard/events" component={ Events }/>
            <PrivateRoute path="/dashboard" component={ Events }/>

            { /* DASHBOARD LOGIN */ }
            <Route path="/login" component={ LoginForm }/>
            <Route path="/forgot" component={ ForgotForm }/>
            <Route path="/register" component={ RegisterForm }/>

            <Route component={ PageNotFound }/>
            <Redirect from="/" to="/"/>
        </Switch>
    </Router>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
