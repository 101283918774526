import React from 'react';
import openSocket from 'socket.io-client';
//import { useNetworkStatus } from 'react-adaptive-hooks/network';

import { Auth, Votation, Thanks, Results, Error, Waiting } from './index';

import '../../styles/index.scss';
import '../../styles/frontuser/frontuser-main.scss';

// todo: fix errors reported by react (the eq signs)

// todo: Finish DEBUG_MODE to quickly test screen
const DEBUG_MODE = false;


class App extends React.Component {
    state = {
        socket: null,
        error: false,
        change: true,
        access: localStorage.getItem('token'),
        location: null,
        ideaId: null,
        display: null,
        //isDisconnected: false,
    };


    componentDidMount() {
        // llamada al socket
        let socket;

        localStorage.debug = 'socket.io-client:socket';
        ///const { effectiveConnectionType } = useNetworkStatus();

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);


        if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'docker') {
            socket = openSocket({ path: '/socket', transports: ['websocket'] });
        } else {
            socket = openSocket('//localhost:3000/', { path: '/socket', transports: ['websocket'] });
        }

        // paso de credenciales
        socket.on('profile', (profile) => {
            if (profile.access) {
                localStorage.setItem('token', profile.access);
                this.setState({ access: profile.access });
            }
            this.setState({ nickname: profile.nickname });
        });

        socket.on('error', err => {
            this.setState({ error: true });
            console.log(err);
        });

        socket.on('connect', () => {
            if (localStorage.getItem('token')) {
                this.signIn();
            }
        });

        socket.on('reconnect', () => {
            console.log('reconnect');
            this.setState({ error: false });
        });

        socket.on('reconnect_attempt', () => {
            console.log('reconnect_attempt');
            //socket.io.opts.transports = ['polling', 'websocket'];
        });

        socket.on('display', (display) => {
            console.log('display', display);
            this.setState({ display });
        });

        this.setState({ socket });
    }


    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }


    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            const webPing = setInterval(
                () => {
                    fetch('https://google.com.ar/', { mode: 'no-cors' })
                        .then(() => {
                            this.setState({ isDisconnected: false }, () => {
                                return clearInterval(webPing);
                            });
                        }).catch(() => this.setState({ isDisconnected: true }));
                }, 1000);
            return;
        }

        return this.setState({ isDisconnected: true });
    };


    signIn = () => {
        let { code, nickname, socket, access } = this.state;
        socket.emit('sign_in', {
            token: code, nickname, access,
        });
    };


    openSocket() {
        let socket;
        if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'docker') {
            socket = openSocket({ path: '/socket', transports: ['websocket', 'polling'] });
        } else {
            socket = openSocket('http://localhost:3000/', { path: '/socket', transports: ['websocket', 'polling'] });
        }
        return socket;
    }


    resetSession = (socket) => {
        console.log('reset session');
        localStorage.removeItem('token');
        socket.disconnect();
        socket.connect();
        // enviar al login
        this.setState({ socket, display: null });

        // TODO eliminar este reload
        window.location.reload(); // esto lo dejamos porque habia un caso de uso que podia fallar
    }


    getSocket = () => this.state.socket;


    render() {
        const { display, socket } = this.state;

        //'disconnected' pierde la conexión o es desconectado por moderador
        if (this.state.isDisconnected) {
            return <Error socket={this.getSocket} message={'Connection Error'} />;
        }

        /*
        * 'entering'  entrando a socket
        * 'enabled' habilitado en la room
        * 'disabled' deshabilitado en la room
        * 'voting' esperando voto
        * 'voted' ya votó
        * 'waiting' esperando siguiente votación
        * 'closed_room' room cerrada
        */


        if (DEBUG_MODE || socket) {
            if (DEBUG_MODE || (display !== null)) {
                let displayState = display.state;

                if (DEBUG_MODE) {
                    //displayState = 'error';
                    displayState = 'votation';
                }

                switch (displayState) {
                    case 'error':
                        return <Error socket={socket} message={display.message}
                            clearToken={display.clearToken} onResetSession={this.resetSession} />;
                    case 'entering':
                        return <Waiting socket={socket} entry={1} onResetSession={this.resetSession} />;
                    case 'enabled':
                        return <Waiting socket={socket} entry={2} onResetSession={this.resetSession} />;
                    case 'votation':
                        return <Votation socket={socket} idea={display.idea} />;
                    case 'voting':
                        return <Votation socket={socket} idea={display.idea} />;
                    case 'waiting':
                        return <Thanks socket={socket} onResetSession={this.resetSession} />;
                    case 'voted':
                        return <Thanks socket={socket} onResetSession={this.resetSession} />;
                    case 'thanks':
                        return <Thanks socket={socket} onResetSession={this.resetSession} />;
                    case 'score':
                        // console.log('display => ', display)
                        return <Results socket={socket} results={display.score} onResetSession={this.resetSession} />;
                    case 'disabled':
                        return <Error socket={socket} message={'You don\'t have access to the event'} onResetSession={this.resetSession} />;
                    case 'closed_room':
                        return <Waiting socket={socket} entry={5} onResetSession={this.resetSession} />;
                    default:
                        return <Error socket={socket} message={display.message} onResetSession={this.resetSession} />;
                }
            } else {
                return <Auth socket={socket} />;
            }
        } else {
            return <Error socket={socket} message={'Connection Error'} onResetSession={this.resetSession} />;
        }
    }
}

export { App };
