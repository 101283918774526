import React from 'react';
import {Table, message, Divider, Popconfirm } from 'antd';
import axios from 'axios';

import { LikeOutlined, DislikeOutlined, KeyOutlined, EditOutlined } from '@ant-design/icons';
import { UserRol, UserStatus } from '../../common/Utils';

import '../../../../styles/dashboard/dashboard-main.scss';

class ListUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('security'),
      profile: null,
      loading: false,
      showUserList: true,
      showUserCreate: false,
      password: undefined,
      confirm: undefined,
      formFull: true,
      formFields: false,
      message: "",
      showForm: true,
      title: "Create",
      editUserId: undefined,
      users: [],
      columns: [
        {
          title: '#',
          dataIndex: 'number_log',
        },
        {
          title: 'Name',
          dataIndex: 'fullname',
          key: 'fullname',
          sorter:	(a, b) => a.fullname.localeCompare(b.fullname)
        },
        {
          title: 'Username',
          dataIndex: 'username',
          key: 'username',
          sorter:	(a, b) => a.username.localeCompare(b.username)
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          sorter:	(a, b) => a.email.localeCompare(b.email)
        },
        {
          title: 'Rol',
          dataIndex: 'rol',
          key: 'rol',
          filters: [
            {
                text: 'Administrador',
                value: 'admin',
            },
            {
                text: 'Moderator',
                value: 'moderator',
            },
          ],
          onFilter: (value, user) => user.rol.indexOf(value) === 0,
          sorter:	(a, b) => a.rol.localeCompare(b.rol),
          render: UserRol
        },
        {
          title: 'Status',
          dataIndex: 'state',
          key: 'state',
          filters: [
            {
                text: 'Active',
                value: 'active',
            },
            {
                text: 'Inactive',
                value: 'inactive',
            },
            {
              text: 'Deleted',
              value: 'deleted',
          },
          ],
          onFilter: (value, user) => user.state.indexOf(value) === 0,
          sorter:	(a, b) => a.state.localeCompare(b.state),
          render: UserStatus
        },
        {
          title: 'Action',
          dataIndex: '',
          key: 'x',
          render: (user) => (
            <div style={{ textAlign: 'center'}}>
              {(user.rol !== 'admin' && user.state !=null && user.state == 'inactive') && <LikeOutlined onClick={()=> this.activateUser(user._id, true)}/> }
              {(user.rol !== 'admin' && user.state !=null && user.state == 'active') && <DislikeOutlined onClick={()=> this.activateUser(user._id, false)}/> }
              {(user.rol !== 'admin') && <Divider type="vertical" />}
              {(user.rol !== 'admin') &&
                <Popconfirm placement="topLeft" title='do you want to reset the password?' onConfirm={() => this.resetPassword(user)} okText="Yes" cancelText="No">
                  <KeyOutlined />
                </Popconfirm>
              }
              {(user.rol !== 'admin') && <Divider type="vertical" />}
              {(user.rol !== 'admin') && <EditOutlined onClick={()=> this.editFields(user)}/>}
            </div>
          )
        },
      ]
    }
    this.handleCreate = this.handleCreate.bind(this);
  }


  editFields(user) {
    this.setState({
      showUserCreate: true,
      showUserList: false,
      formFields: true,
      formFull: false,
      title: "Edit",
      editUserId: user._id
    })

    this.props.handleEdit(user);
  }

  resetPassword(user) {
    const data = {
      _id: user._id
    }
    axios.put('/api/v1/user/reset', data, {
      headers: {
        'Authorization': `Bearer ${this.state.token}`,
      }
    })
    .then((response) => {
      this.getUsers()
      message.success("The password has been changed")
    })
    .catch((error) => {
      console.log(error);
    });
  }

  activateUser(id, flag) {
    const data = {
			_id: id
		}
    if (flag) {
      axios.post('/api/v1/auth/activate', data, {
        headers: {
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then((response) => {

          this.getUsers()
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios.post('/api/v1/auth/disable', data, {
        headers: {
          'Authorization': `Bearer ${this.state.token}`,
        }
      })
        .then((response) => {
          this.getUsers()
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  componentDidMount() {
    this.getUsers()
  }

  getUsers() {
    axios.get('/api/v1/user', {
      headers: {
        'Authorization': `Bearer ${this.state.token}`,
      }
    })
      .then((response) => {
        this.setState({ users: response.data.content.map((rw, i) => Object.assign(rw, { number_log: i + 1 })) })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCreate(){
    this.getUsers()
    this.setState({showUserList: true, showUserCreate: false})
  }

  render() {
    const { columns, users} = this.state

    return (
        <Table
          columns={columns}
          dataSource={users}
          rowKey={user => user._id}
        />

    )
  }
}

export { ListUsers }
