import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

// TODO: Make pagenotfound.scss, and see how to trigger that screen
import '../../styles/index.scss';
import '../../styles/colors.scss';

import warning from '../../images/warning.png';

class PageNotFound extends React.Component {

  render() {
    return (
      <Container className="waiting">
        <Row className="justify-content-sm-center">
          <Col xs sm={10} md={6} lg={4} className="pt-3 pb-3 head">
            <span className="head-title">The Fire Scale</span>
          </Col>
        </Row>

        <Row className="justify-content-sm-center">
          <Col xs sm={10} md={6} lg={4} className="text-center pt-5 pb-5">
            <Image src={warning} round="true" fluid className="pt-5 mt-5" />
          </Col>
        </Row>


        <Row className="justify-content-sm-center">
          <Col xs sm={10} md={6} lg={4} className="pt-5 pb-5">
            <h3 className="text-center white">404 PAGE NOT FOUND!</h3>
            <br /><br /><br /><br />
          </Col>
        </Row>
      </Container>
    )
  }
}
export { PageNotFound }
