import React from "react";
import { Tag } from 'antd';

export const StatusRoom = (status) => {
    switch (status) {
        case 'created':
            return <Tag color="geekblue">Created</Tag>;
        case 'open':
            return <Tag color="blue">Open</Tag>;
        case 'votation':
            return <Tag color="green">Votation</Tag>;
        case 'closed':
            return <Tag color="magenta">Closed</Tag>;
        case 'score':
            return <Tag color="purple">Score</Tag>;
        default:
            return <Tag color="grey">Empty</Tag>;
    }
}

export const UserRol = (rol) => {
    switch (rol) {
        case 'moderator':
            return <Tag color="blue">Moderator</Tag>;
        case 'admin':
            return <Tag color="geekblue">Administrator</Tag>;
        default:
            return <Tag color="red">Empty</Tag>;
    }
}

export const UserStatus = (status) => {
    switch (status) {
        case 'inactive':
            return <Tag color="orange">Inactive</Tag>;
        case 'active':
            return <Tag color="green">Active</Tag>;
        case 'deleted':
            return <Tag color="red">Deleted</Tag>;
        default:
            return <Tag color="grey">Empty</Tag>;
    }
}

export const StatusIdea = (status) => {
    switch (status) {
        case 'created':
            return <Tag color="green">Send to vote</Tag>;
        case 'votation':
            return <Tag color="purple">Close votation</Tag>;
        case 'closed':
            return <Tag color="magenta">Closed</Tag>;
        default:
            return <Tag color="magenta">{status}</Tag>;
    }
}


export const StatusClient = (status) => {
   
    // { text: 'Disconnected', value: 'disconnected' },
   
    switch (status) {
        case 'entering':
            return <Tag color="blue">Entering</Tag>;
        case 'disabled':
            return <Tag color="grey">Disabled</Tag>;
        case 'enabled':
            return <Tag color="green">Enabled</Tag>;
        case 'voting':
            return <Tag color="volcano">Voting</Tag>;
        case 'voted':
            return <Tag color="geekblue">Voted</Tag>;
        case 'disconnected':
            return <Tag color="magenta">Disconnected</Tag>;
        default:
            return <Tag color="purple">{status}</Tag>;
    }
}